<template>
  <div class="son-page">
      <div @click="backBtn" class="back_box">
          <div class="pointer"><Icon size="24" type="ios-arrow-back" /></div>
          <div class="ml10">云存储</div>
      </div>
      <div class="content_box">
          <RadioGroup class="checks_box" @on-change="jianting" v-model="formData.service_type">
              <Radio :class="formData.service_type==4?'check_kuang':'kuang'" :label="4">本地存储</Radio>
              <Radio :class="formData.service_type==1?'check_kuang':'kuang'" :label="1">腾讯云存储</Radio>
              <Radio :class="formData.service_type==2?'check_kuang':'kuang'" :label="2">阿里云存储</Radio>
              <Radio :class="formData.service_type==3?'check_kuang':'kuang'" :label="3">七牛云存储</Radio>
          </RadioGroup>

          <Form v-if="formData.service_type!=4" label-position="left" style="width:500px;margin-top:20px" :label-width="100">
              <FormItem label="域名：">
                  <Input v-model="formData.domain"></Input>
              </FormItem>
              <FormItem label="access_key：">
                  <Input v-model="formData.access_key"></Input>
              </FormItem>
              <FormItem label="secret_key：">
                  <Input v-model="formData.secret_key"></Input>
              </FormItem>
              <FormItem label="存储名称：">
                  <Input v-model="formData.storage_title"></Input>
              </FormItem>
              <FormItem label="地域：">
                  <Input v-model="formData.area"></Input>
              </FormItem>
          </Form>
      </div>
      <div class="bottom_btn">
          <Button @click="addbtn" class="btn">保存</Button>
      </div>
  </div>
</template>

<script>
import {infoStorage,setStorage,getStorage} from '@/api/setting'
export default {
  name: "password",
  data() {
    return {
      formData:{
        service_type:4,
        domain:'',
        access_key:'',
        secret_key:'',
        storage_title:'',
        area:'',
      },
    }
  },
  created() {
    // this.infoStorage()
    this.getStorage()
  },
  methods: {
    infoStorage(){
      infoStorage(this.formData).then(res=>{
        this.formData.domain = res.data.domain
        this.formData.access_key = res.data.access_key
        this.formData.secret_key = res.data.secret_key
        this.formData.storage_title = res.data.storage_title
        this.formData.area = res.data.area
      })
    },
    getStorage(){
      getStorage().then(res=>{
        if (res.data.length!=0) {
          this.formData = res.data
        }
      })
    },
    jianting(){
      this.infoStorage()
    },
    addbtn(){
      setStorage(this.formData).then(res=>{
        this.$Message.success(res.msg)
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
      backBtn(){
          this.$emit('backview',0)
      }
  }
}
</script>

<style scoped>
.son-page{
  position: relative;
}
.back_box{
    width: 100%;
    cursor: pointer;
    background: #FFF;
    border-radius: 12px;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    align-items: center;
    padding: 30px;
}
.content_box{
    width: 100%;
    height: 75vh;
    overflow: auto;
    margin-top: 30px;
    background: #FFF;
    border-radius: 12px;
    padding: 30px;
}
.kuang{
  border: 1px solid #eee;
  padding: 5px 16px;
  border-radius: 5px;
}
.check_kuang{
  border: 1px solid #FF761D;
  color: #FF761D;
  padding: 5px 16px;
  border-radius: 5px;
}
.bottom_btn{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 100px;
    border-top: 1px solid #eee;
    background: #FFF;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.checks_box{
  display: flex;
  justify-content: space-between;
  width: 600px;
}
</style>
