<template>
    <div class="son-page">
        <div class="top-change">
            <Form label-position="left" :label-width="90" inline>
                <FormItem style="margin-bottom:0" label="启用评价：">
                    <i-switch @on-change="rechargeChange" v-model="formData.comment_status" size="large" :true-value="1" :false-value="0">
                        <span slot="open">开启</span>
                        <span slot="close">关闭</span>
                    </i-switch>
                </FormItem>
                <FormItem v-if="formData.comment_status==1" style="margin-bottom:0;margin-left:-50px" label="">
                    <CheckboxGroup v-model="formData.comment_mode">
                        <Checkbox style="margin-right:30px" :label="1">堂食</Checkbox>
                        <Checkbox style="margin-right:30px" :label="2">外卖</Checkbox>
                        <Checkbox style="margin-right:30px" :label="3">自取</Checkbox>
                    </CheckboxGroup>
                </FormItem>
            </Form>
            <div><Button @click="subBtn" class="btn">保存</Button></div>
        </div>
        <div class="Finance_box">
            <div class="top-search">
                <DatePicker @on-change="timeCheck" type="daterange" placement="bottom-end" placeholder="点击选择日期" style="width: 300px;margin-right: 20px"></DatePicker>
            </div>
            <div class="Finance_box_title DW_nei_txt">评价统计</div>
            <div class="view_right_num">
                <div style="border-right: 1px solid #eee;" class="item_right_num">
                    <div class="item_title">总评价分</div>
                    <div class="item_num">{{info.count}}</div>
                </div>
                <div style="border-right: 1px solid #eee;" class="item_right_num">
                    <div class="item_title">好评统计</div>
                    <div class="item_num">{{info.hao_count}}</div>
                </div>
                <div style="border-right: 1px solid #eee;" class="item_right_num">
                    <div class="item_title">中评统计</div>
                    <div class="item_num">{{info.zhong_count}}</div>
                </div>
                <div class="item_right_num">
                    <div class="item_title">差评统计</div>
                    <div class="item_num">{{info.cha_count}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {commentChange,commentHome,storeComment,changeCommentMode} from '@/api/evaluation'
    export default {
        data(){
            return{
                storePrams:{
                    start_time:'',
                    end_time:'',
                },
                formData:{
                    comment_mode:[],
                    comment_status:1
                },
                info:{}
            }
        },
        created(){
            this.getCommentHome()
            this.getstoreComment()
        },
        methods:{
            // 获取数据
            getCommentHome(){
                commentHome(this.storePrams).then(res=>{
                    this.info = res.data
                })
            },
            // 获取评论开关状态
            getstoreComment(){
                storeComment().then(res=>{
                    this.formData = res.data
                })
            },
            // 时间选择
            timeCheck(e){
                this.storePrams.start_time = e[0]
                this.storePrams.end_time = e[1]
                this.getCommentHome()
            },
            // 评价功能开关
            rechargeChange(e){
                commentChange({comment_status:e}).then(res=>{
                    this.$Message.success(res.msg)
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            // 保存模式按钮
            subBtn(){
                changeCommentMode({comment_mode:this.formData.comment_mode}).then(res=>{
                    this.$Message.success(res.msg)
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            }
        }
    }
</script>

<style scoped>
    .top-change {
        width: 100%;
        background: white;
        border-radius: 12px;
        padding: 20px 30px;
        margin-bottom: 30px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .btn{
        background: #FF761D;
        color: #fff;
        border-color: #FF761D;
        margin-left: 20px;
    }
    .Finance_box{
        width: 100%;
        padding: 20px 30px;
        border-radius: 12px;
        background: #FFF;
        margin: 0 0 30px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .top-search{
        padding: 0 0 20px;
    }
    .Finance_box_title::before{
        content: ' ';
        display: block;
        width: 2px;
        height: 16px;
        margin-right: 10px;
        background: #FF761D;
    }
    .DW_nei_txt{
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
    }
    .view_right_num{
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .item_right_num{
        width: 25%;
        text-align: center;
    }
    .item_num{
        margin-top: 10px;
        font-weight: 700;
        font-size: 20px;
    }
    .item_title{
        font-size: 16px;
    }
    .ivu-switch-checked{
        border-color: #FF761D;
        background-color: #FF761D;
    }
</style>