<template>
    <div class="son-page">
        <div class="top-change">
            <Tabs value="1" @on-click="changeTab">
                <TabPane :label="label" name="1"></TabPane>
                <TabPane :label="label1" name="2"></TabPane>
                <TabPane label="已结单" name="6"></TabPane>
                <TabPane :label="label2" name="1009"></TabPane>
            </Tabs>
            <div class="top-search">
                <Input
                    placeholder="请输入单号、编号、桌号"
                    v-model="storePrams.keyword"
                    style="width: 400px"
                />
                <div class="two-btns">
                    <div @click="searchBtn" class="btn-style">查询</div>
                </div>
            </div>
        </div>
        <div v-if="orderList.length != 0" class="order-list">
            <div
                class="order-item"
                v-for="(item, index) of orderList"
                :key="index"
            >
                <!-- <div v-if="item.business_status==6" class="status-pic"><img src="../../../assets/2.png"/></div> -->
                <div v-if="item.business_status == -1" class="status-pic">
                    <img src="../../../assets/status/1.png" />
                </div>
                <div class="l-went">
                    <div class="top-num">
                        <div class="name1">单号：{{ item.number }}</div>
                        <div class="name1">编号：{{ item.number_no }}</div>
                        <div class="name1">下单日期：{{ item.created_at }}</div>
                    </div>
                    <div class="shop_flex">
                        <div
                            v-for="(j, k) of item.goods_data"
                            :key="k"
                            class="bottom-content"
                        >
                            {{ j.goods_name }}
                            <span v-if="j.prices.specs"
                                >({{ j.prices.specs }})</span
                            >
                            <span
                                style="margin-left: 10px"
                                v-for="(a, s) of j.attrs"
                                :key="s"
                            >
                                {{ a.name }}:{{ a.val }}
                            </span>
                            <span
                                v-for="(o, p) of j.foods"
                                :key="p + 's'"
                                style="margin-left: 10px"
                                >{{ o.name }}</span
                            >
                            <span style="font-weight: 700; margin-left: 10px"
                                >x {{ j.num }}</span
                            >
                        </div>
                    </div>
                    <div v-if="item.remarks" style="margin-top: 10px">
                        备注：{{ item.remarks }}
                    </div>
                </div>
                <div class="r-bent">
                    <div class="right1">
                        桌位:
                        <span style="font-size: 20px; font-weight: bold">{{
                            item.desk_number
                        }}</span>
                    </div>
                    <div>
                        <div class="right1">
                            订单总额:
                            <span
                                style="
                                    font-size: 16px;
                                    font-weight: bold;
                                    color: #ff5c11;
                                "
                                >{{ item.order_sum }}元</span
                            >
                        </div>
                        <div class="right-btn">
                            <div
                                style="width: 100px"
                                :style="
                                    item.pay_status == 1
                                        ? 'color:red'
                                        : 'color:green'
                                "
                            >
                                {{
                                    item.pay_status == 1 ? "未支付" : "支付成功"
                                }}
                            </div>
                            <div
                                style="width: 65px"
                                @click="Paymentdetails(item)"
                                class="btn1"
                            >
                                订单明细
                            </div>
                            <div
                                v-if="
                                    item.cancel_status != 0 &&
                                    storePrams.business_status == '6'
                                "
                                @click="reasonBtn(item)"
                                style="
                                    width: 65px;
                                    border: 1px dashed #999;
                                    color: #999;
                                "
                                class="btn1"
                            >
                                {{
                                    item.cancel_status == 1
                                        ? "退款原因"
                                        : item.cancel_status == 2
                                        ? "同意取消"
                                        : "拒绝取消"
                                }}
                            </div>
                            <div
                                style="width: 65px"
                                v-if="item.foods_status"
                                @click="LookDish(item.id)"
                                class="btn1"
                            >
                                查看加菜
                            </div>
                            <div
                                v-if="item.business_status == 1"
                                @click="receivingBtn(item.id)"
                                class="btn1"
                            >
                                接单
                            </div>
                            <div
                                v-if="
                                    item.business_status == 2 &&
                                    storePrams.business_status != '1009'
                                "
                                @click="statementBtn(item.id)"
                                class="btn1"
                            >
                                结单
                            </div>
                            <div
                                v-if="
                                    (item.business_status == 1 ||
                                        item.business_status == 2) &&
                                    storePrams.business_status != '1009'
                                "
                                @click="cancelBtn(item.id)"
                                class="btn1 btn2"
                            >
                                取消
                            </div>
                            <div
                                v-if="
                                    storePrams.business_status == '1009' &&
                                    item.cancel_status != 0
                                "
                                @click="reasonBtn(item)"
                                class="btn1 btn2"
                            >
                                处理申请
                            </div>
                            <div @click="PrintBtn(item.id)" class="btn1 btn2">
                                打 印
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tishi" v-else>
            <div>
                <img
                    style="width: 400px; height: 400px"
                    src="../../../assets/order.png"
                    alt=""
                />
            </div>
            <div>暂无数据~</div>
        </div>
        <div v-if="orderList.length != 0" class="Page_box">
            <Page
                :total="total"
                :current="storePrams.page"
                style="text-align: right"
                show-elevator
                show-total
                @on-change="pageChange"
                :page-size="storePrams.limit"
            />
        </div>

        <Modal v-model="dishModal" width="600" title="加菜详情">
            <div class="dish_da_box">
                <div
                    class="dish_box"
                    v-for="(item, index) of dishData"
                    :key="index"
                >
                    <div class="dish_box_time">
                        <div>下单日期：{{ item.created_at }}</div>
                        <div>
                            <Button @click="Addprinting(item.id)">打印</Button>
                        </div>
                    </div>
                    <div
                        style="color: #999"
                        v-for="(j, k) of item.goods_list"
                        :key="k"
                        class="bottom-content"
                    >
                        {{ j.goods_name }} ({{ j.prices.specs }})
                        <span
                            style="margin-left: 10px"
                            v-for="(a, s) of j.attrs"
                            :key="s"
                        >
                            {{ a.name }}:{{ a.val }}
                        </span>
                        <span
                            v-for="(o, p) of j.foods"
                            :key="p + 's'"
                            style="margin-left: 10px"
                            >{{ o.name }}</span
                        >
                        <span style="font-weight: 700; margin-left: 10px"
                            >x {{ j.num }}</span
                        >
                    </div>
                    <div style="padding: 5px 0" v-if="item.remarks">
                        备注信息：{{ item.remarks }}
                    </div>
                    <div class="dish_money_box">
                        <div
                            :style="
                                item.pay_status == 1
                                    ? 'color:red'
                                    : 'color:green'
                            "
                        >
                            {{ item.pay_status == 1 ? "未支付" : "支付成功" }}
                        </div>
                        <div>
                            金额：<span class="dish_money"
                                >{{ item.pay_money }}元</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer"></div>
        </Modal>
        <refundWin
            :reasonModal.sync="reasonModal"
            :reasonData.sync="reasonData"
        ></refundWin>
        <OrderInfo :order_id.sync="order_id"></OrderInfo>
        <printerWin
            :PrintModal.sync="PrintModal"
            :PrintData.sync="PrintData"
            :dayingStatus="dayingStatus"
            :orderType="1"
        ></printerWin>
    </div>
</template>

<script>
import OrderInfo from "@/components/order/info.vue";
import refundWin from "@/components/order/refund.vue";
import printerWin from "@/components/printer/printerWin.vue";
import {
    getUntreated,
    tsOrderList,
    getTsOrderBranch,
    tsOrderHandle,
} from "@/api/order";
import cloudstorageVue from "../../setting/components/components/cloudstorage.vue";
export default {
    name: "canteen",
    components: {
        OrderInfo,
        printerWin,
        refundWin,
    },
    data() {
        return {
            PrintData: {
                print_id: 0,
                id: null,
            },
            order_id: 0,
            reasonModal: false,
            reasonData: {
                order_id: null,
                type: null,
                cancel_status: null,
                cancel_reason: "",
                merchant_reason: "",
            },
            PaymentData: {},
            single: true,
            single1: false,
            PrintModal: false,
            dayingStatus: null,
            PrintList: [],
            total: 0,
            dishModal: false,
            paymentModal: false,
            dishData: [],
            storePrams: {
                type: 1,
                keyword: "",
                page: 1,
                limit: 10,
                business_status: "1",
            },
            orderList: [],
            label: (h) => {
                return h("div", [
                    h("span", "待接单"),
                    h("Badge", {
                        props: {
                            count: this.$store.state.order_untreated.t
                                .receive_num,
                        },
                    }),
                ]);
            },
            label1: (h) => {
                return h("div", [
                    h("span", "配餐中"),
                    h("Badge", {
                        props: {
                            count: this.$store.state.order_untreated.t
                                .catering_num,
                        },
                    }),
                ]);
            },
            label2: (h) => {
                return h("div", [
                    h("span", "售后"),
                    h("Badge", {
                        props: {
                            count: this.$store.state.order_untreated.t
                                .approve_num,
                        },
                    }),
                ]);
            },
        };
    },
    computed: {
        order_untreated() {
            return this.$store.state.order_untreated;
        },
    },
    created() {
        this.getList();
    },
    methods: {
        getList() {
            tsOrderList(this.storePrams).then((res) => {
                this.total = res.data.total;
                this.orderList = res.data.data;
            });
        },
        dishOk() {
            // 加菜弹框确定按钮
        },
        dishCancel() {
            // 加菜弹框取消按钮
        },
        PrintBtn(e) {
            this.dayingStatus = 1;
            this.PrintData.id = e;
            this.PrintModal = true;
            // orderPrint({id:e}).then(res=>{
            //   console.log(res);
            //   console.log('新的订单');
            // })
        },
        // 加菜打印
        Addprinting(e) {
            this.dayingStatus = 2;
            this.PrintData.id = e;
            this.PrintModal = true;
        },
        LookDish(id) {
            getTsOrderBranch({ id: id }).then((res) => {
                this.dishData = res.data;
                this.dishModal = true;
            });
        },
        // 退款原因
        reasonBtn(e) {
            this.reasonData.cancel_reason = e.cancel_reason;
            this.reasonData.cancel_status = e.cancel_status;
            this.reasonData.order_id = e.id;
            this.reasonModal = true;
        },
        // 点击订单明细时触发
        Paymentdetails(e) {
            console.log('点击订单明细时触发',e)
            this.order_id = e.id;
            this.PaymentData = e;
            // this.paymentModal = true
        },
        receivingBtn(id) {
            this.$Modal.confirm({
                title: "提示",
                content: `您正在使用接单功能`,
                onOk: () => {
                    tsOrderHandle({ id: id, type: 1 })
                        .then((res) => {
                            this.$Message.success(res.msg);
                            this.$parent.$parent.$parent.$parent.getUntreated();
                            this.$parent.getMsgFormSon();
                            this.getList();
                        })
                        .catch((err) => {
                            this.$Message.error(err.msg);
                        });
                },
                onCancel: () => {},
            });
        },
        cancelBtn(id) {
            this.$Modal.confirm({
                title: "提示",
                content: `您正在使用取消功能`,
                onOk: () => {
                    tsOrderHandle({ id: id, type: -1 })
                        .then((res) => {
                            this.$Message.success(res.msg);
                            this.$parent.$parent.$parent.$parent.getUntreated();
                            this.$parent.getMsgFormSon();
                            this.getList();
                        })
                        .catch((err) => {
                            this.$Message.error(err.msg);
                        });
                },
                onCancel: () => {},
            });
        },
        statementBtn(id) {
            this.$Modal.confirm({
                title: "提示",
                content: `您正在使用结单功能`,
                onOk: () => {
                    tsOrderHandle({ id: id, type: 4 })
                        .then((res) => {
                            this.$Message.success(res.msg);
                            this.$parent.$parent.$parent.$parent.getUntreated();
                            this.$parent.getMsgFormSon();
                            this.getList();
                        })
                        .catch((err) => {
                            this.$Message.error(err.msg);
                        });
                },
                onCancel: () => {},
            });
        },
        searchBtn() {
            this.storePrams.page = 1;
            this.getList();
        },
        changeTab(e) {
            this.storePrams.page = 1;
            this.storePrams.business_status = e;
            this.getList();
        },
        pageChange(index) {
            this.storePrams.page = index;
            this.getList();
        },
    },
};
</script>

<style scoped>
/* .son-page {

} */
.top-change {
    width: 100%;
    background: white;
    border-radius: 12px;
    padding: 20px 30px;
    box-sizing: border-box;
}
.top-search {
    width: 100%;
    background: white;
    /* padding: 20px 0; */
    border-radius: 10px;
    display: flex;
}
.two-btns {
    display: flex;
    align-items: center;
    margin-left: 40px;
}
.btn-style {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 6px;
    background: #ff761d;
    width: 80px;
    height: 32px;
}
.order-item {
    margin-top: 20px;
    background: white;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: relative;
}
.top-num {
    border-bottom: 1px dotted #eee;
    padding-bottom: 10px;
    font-size: 13px;
    color: #585858;
    font-weight: bold;
    display: flex;
    align-items: center;
}
.top-num .name1 {
    margin-right: 40px;
}
.bottom-content {
    width: 100%;
    margin-right: 20px;
    font-size: 15px;
    /* margin-top: 20px; */
    word-break: break-all;
}
.r-bent {
    max-width: 480px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.right1 {
    text-align: right;
    margin-bottom: 16px;
}
.l-went {
    flex: 1;
}
.right-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.right-btn .btn1 {
    width: 60px;
    height: 30px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ff5c11;
    color: #ff5c11;
    margin-left: 20px;
    border-radius: 4px;
    cursor: pointer;
}
.right-btn .btn2 {
    color: #343434;
    border: 1px solid #555555;
}
.right-btn .btn2:hover,
.right-btn .btn1:hover {
    opacity: 0.6;
}
.status-pic {
    width: 117px;
    height: 88px;
    position: absolute;
    right: 210px;
    top: 50%;
    transform: translateY(-50%);
}
.status-pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.tishi {
    text-align: center;
    color: #999;
    /* line-height: 100px; */
}
.dish_da_box {
    max-height: 600px;
    overflow: auto;
}
.dish_box {
    padding: 10px 0;
}
.dish_box_time {
    font-weight: bold;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dish_money_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dish_money {
    color: #ff5c11;
    font-weight: bold;
    font-size: 16px;
}
.flex {
    display: flex;
}
.align-center {
    align-items: center;
}
.just-sb {
    justify-content: space-between;
}
.btn {
    background: #ff761d;
    color: #fff;
    border-color: #ff761d;
}
.size_18 {
    font-size: 16px;
}
.color_FF8400 {
    color: #ff8400;
}
</style>
<style>
.ivu-tabs-nav .ivu-tabs-tab {
    display: inline-block;
    height: 100%;
    padding: 8px 16px;
    margin-right: 16px;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    font-size: 15px;
    font-weight: bold;
    transition: color 0.3s ease-in-out;
}
.ivu-tabs-nav .ivu-tabs-tab-active {
    color: #ff761d;
}
.ivu-tabs-nav .ivu-tabs-tab:hover {
    color: #ff761d;
}
.ivu-tabs-ink-bar {
    height: 2px;
    box-sizing: border-box;
    background-color: #ff761d;
    position: absolute;
    left: 0;
    bottom: 1px;
    z-index: 1;
    transition: transform 0.3s ease-in-out;
    transform-origin: 0 0;
}
.shop_flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
}
.ivu-badge {
    margin-left: 5px;
}
.order-list {
    width: 100%;
    height: 65vh;
    overflow: auto;
}
.Page_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
    border-radius: 12px;
    width: 100%;
    height: 70px;
    background: #fff;
    margin-top: 20px;
}
.ivu-radio-checked .ivu-radio-inner {
    border-color: #ff761d;
}
.ivu-radio-inner:after {
    background-color: #ff761d;
}
.ivu-btn-primary {
    background-color: #ff761d;
    border-color: #ff761d;
}
</style>
