<template>
    <div class="son-page">
        <div class="top-change">
            <div class="top-search">
                <Input placeholder="请输入ID、标签名称" v-model="storePrams.keyword" style="width: 300px;margin-right: 20px"/>
                <Button @click="searchBtn" class="btn">查询</Button>
            </div>
        </div>
        <div class="table-wrap">
            <div style="margin-bottom:20px">
                <Button @click="ModalBtn" class="btn">新增标签</Button>
                <Button @click="Batchdelete" class="btn">批量删除</Button>
            </div>
            <Table @on-selection-change="selectionChange" :columns="Columns" :data="data">
                <template slot-scope="{ index }" slot="index">
                    <div>{{ index + 1 }}</div>
                </template>
                <template slot-scope="{ row }" slot="status">
                    <i-switch @on-change="statusChange(row.id)" size="large" v-model="row.status" :true-value="1" :false-value="0">
                        <span slot="open">显示</span>
                        <span slot="close">隐藏</span>
                    </i-switch>
                </template>
                <template slot-scope="{ row }" slot="operation">
                    <Button @click="ModalBtn(row)" type="info" ghost size="small" style="margin-right: 5px">编辑</Button>
                    <Button @click="delBtn(row.id)" type="error" ghost size="small">删除</Button>
                </template>
            </Table>
            <br/>
            <Page :total="total" :current="storePrams.page" style="text-align:right" show-elevator show-total @on-change="pageChange" :page-size="storePrams.limit"/>
        </div>
        <!-- 商家回复弹框 -->
        <Modal
            v-model="Modal"
            :title="ModalTitle"
            @on-cancel="Modalcancel">
            <Form style="padding:0 30px" label-position="left" :label-width="90">
                <FormItem label="标签名称：">
                    <Input v-model="formData.title" style="width:300px" placeholder="请输入标签名称"></Input>
                </FormItem>
                <FormItem label="状态：">
                    <i-switch size="large" v-model="formData.status" :true-value="1" :false-value="0">
                        <span slot="open">显示</span>
                        <span slot="close">隐藏</span>
                    </i-switch>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="Modalcancel">取消</Button>
                <Button class="btn" @click="Modalok">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
    import {labellist,labeladd,labeldel,labelchange} from '@/api/evaluation'
    export default {
        data(){
            return{
                total: 0,
                storePrams:{
                    keyword:'',
                    limit: 10,
                    page: 1
                },
                Columns:[
                    {
                        type: 'selection',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '#',
                        slot: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: 'ID',
                        key: 'id',
                        align: 'center'
                    },
                    {
                        title: '标签名称',
                        key: 'title',
                        align: 'center'
                    },
                    {
                        title: '状态',
                        slot: 'status',
                        align: 'center'
                    },
                    {
                        title: '操作',
                        key: 'action',
                        width: 160,
                        fixed: 'right',
                        align: 'center',
                        slot: 'operation'
                    }
                ],
                formData:{
                    title:'',
                    status:0
                },
                data:[],
                delID:[],
                ModalTitle:'',
                is_recharge:1,
                Modal:false,
            }
        },
        created(){
            this.getList()
        },
        methods:{
            getList(){
                labellist(this.storePrams).then(res=>{
                    this.total = res.data.total
                    this.data = res.data.data
                })
            },
            // 打开弹框按钮
            ModalBtn(e){
                if (e.id) {
                    this.formData.id = e.id
                    this.formData.title = e.title
                    this.formData.status = e.status
                    this.ModalTitle = '编辑标签'
                }else{
                    this.ModalTitle = '新增标签'
                }
                this.Modal = true
            },
            // 标签删除
            delBtn(id){
                this.$Modal.confirm({
                    title: '提示',
                    content: `您正在使用删除功能`,
                    onOk: () => {
                        labeldel({id:id}).then(res=>{
                            this.$Message.success(res.msg)
                            this.getList()
                        }).catch(err=>{
                            this.$Message.error(err.msg)
                        })
                    },
                    onCancel: () => {}
                }); 
            },
            // 标签列表状态开关
            statusChange(id){
                labelchange({id:id}).then(res=>{
                    this.$Message.success(res.msg)
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            // 弹框确定
            Modalok(){
                labeladd(this.formData).then(res=>{
                    this.$Message.success(res.msg)
                    this.getList()
                    this.Modalcancel()
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            // 弹框取消
            Modalcancel(){
                this.Modal = false
                this.formData = {title:'',status:0}
            },
            // 批量选择
            selectionChange(row){
                this.delID = []
                row.forEach(item=>{
                    this.delID.push(item.id)
                })
            },
            // 批量删除
            Batchdelete(){
                if (this.delID.length!=0) {
                    this.$Modal.confirm({
                        title: '提示',
                        content: `您正在使用批量删除功能`,
                        onOk: () => {
                            labeldel({id:this.delID}).then(res=>{
                                this.$Message.success(res.msg)
                                this.getList()
                            }).catch(err=>{
                                this.$Message.error(err.msg)
                            })
                        },
                        onCancel: () => {}
                    }); 
                }
            },
            // 搜索
            searchBtn(){
                this.storePrams.page = 1
                this.getList()
            },
            // 分页
            pageChange(index){
                this.storePrams.page = index
                this.getList()
            },
        }
    }
</script>

<style scoped>
    .top-change {
        width: 100%;
        background: white;
        border-radius: 12px;
        padding: 20px 30px;
        box-sizing: border-box;
    }
    .top-search {
        width: 100%;
        background: white;
        padding: 20px 0;
        border-radius: 10px;
        display: flex;
    }
    .btn{
        background: #FF761D;
        color: #fff;
        border-color: #FF761D;
        margin-right: 20px;
    }
    .table-wrap {
        width: 100%;
        background: white;
        border-radius: 12px;
        padding: 20px 30px;
        box-sizing: border-box;
        margin-top: 30px;
    }
    .ivu-switch-checked{
        border-color: #FF761D;
        background-color: #FF761D;
    }
    
    .flex{
        display: flex;
    }
    .just_sb{
        justify-content: space-between;
    }
</style>
<style>
    .ivu-checkbox-checked .ivu-checkbox-inner{
        border-color: #FF761D;
        background-color: #FF761D;
    }
</style>