import { render, staticRenderFns } from "./evaluationstatistics.vue?vue&type=template&id=4040572e&scoped=true&"
import script from "./evaluationstatistics.vue?vue&type=script&lang=js&"
export * from "./evaluationstatistics.vue?vue&type=script&lang=js&"
import style0 from "./evaluationstatistics.vue?vue&type=style&index=0&id=4040572e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4040572e",
  null
  
)

export default component.exports