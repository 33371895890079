<template>
    <div class="son-page">
        <div class="top-change">
            <div class="top-search">
                <Input placeholder="请输入订单号、手机号、用户昵称" v-model="storePrams.keyword" style="width: 300px;margin-right: 20px"/>
                <DatePicker @on-change="timeCheck" type="daterange" placement="bottom-end" placeholder="点击选择日期" style="width: 300px;margin-right: 20px"></DatePicker>
                <Select clearable v-model="storePrams.comment_type" style="width:200px;margin-right:20px" placeholder="好评度">
                    <Option v-for="item in selectList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
                <Select clearable v-model="storePrams.mode" style="width:200px" placeholder="模式选择">
                    <Option v-for="item in selectList1" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
                <Button @click="searchBtn" class="btn">查询</Button>
            </div>
        </div>
        <div class="table-wrap">
            <!-- <Form label-position="left" :label-width="100">
                <FormItem label="评价功能：">
                    <i-switch @on-change="rechargeChange" size="large" v-model="is_recharge" :true-value="1" :false-value="0">
                        <span slot="open">开启</span>
                        <span slot="close">关闭</span>
                    </i-switch>
                </FormItem>
            </Form> -->
            <Table :columns="Columns" :data="data">
                <template slot-scope="{ index }" slot="index">
                    <div>{{ index + 1 }}</div>
                </template>
                <template slot-scope="{ row }" slot="type">
                    <div>{{ row.type==1?'堂食':row.type==2?'外卖':'自取'}}</div>
                </template>
                <template slot-scope="{ row }" slot="content">
                    <textarea disabled v-model="row.content"></textarea>
                </template>
                <template slot-scope="{ row }" slot="btn">
                    <Button @click="replyBtn(row)">{{row.reply_content?'已回复':'回复'}}</Button>
                </template>
                <template slot-scope="{ row }" slot="status">
                    <i-switch size="large" @on-change="statusChange(row.id)" v-model="row.status" :true-value="1" :false-value="0">
                        <span slot="open">显示</span>
                        <span slot="close">隐藏</span>
                    </i-switch>
                </template>
                <template slot-scope="{ row }" slot="operation">
                    <Button @click="detailBtn(row.id)" type="info" ghost size="small" style="margin-right: 5px">查看</Button>
                    <Button @click="delBtn(row.id)" type="error" ghost size="small">删除</Button>
                </template>
            </Table>
            <br/>
            <Page :total="total" :current="storePrams.page" style="text-align:right" show-elevator show-total @on-change="pageChange" :page-size="storePrams.limit"/>
        </div>
        <!-- 商家回复弹框 -->
        <Modal
            v-model="replyModal"
            title="商家回复"
            @on-cancel="replycancel">
            <textarea v-model="replybox.reply_content" placeholder="请输入商家回复" class="textareaStyle"></textarea>
            <div slot="footer">
                <Button @click="replycancel">取消</Button>
                <Button class="btn" @click="replyok">确定</Button>
            </div>
        </Modal>
        <!-- 查看详情弹框 -->
        <Modal
            :width="500"
            v-model="detailModal"
            title="评价详情"
            @on-ok="detailok">
            <div class="content_box">
                <div class="flex just_sb iten_content">
                    <div>用户昵称：</div>
                    <div>{{detailsData.name}}</div>
                </div>
                <div class="flex just_sb iten_content">
                    <div>订单号：</div>
                    <div>{{detailsData.number_no}}</div>
                </div>
                <div class="flex just_sb iten_content">
                    <div>评分：</div>
                    <div>{{detailsData.satisfied}}</div>
                </div>
                <div class="flex just_sb iten_content">
                    <div>评价时间：</div>
                    <div>{{detailsData.created_at}}</div>
                </div>
                <div class="flex just_sb iten_content">
                    <div>状态：</div>
                    <div>{{detailsData.status==1?'显示':'隐藏'}}</div>
                </div>
                <div class="evaluate_box">
                    <div>评价内容：</div>
                    <div class="evaluate_txt">{{detailsData.content}}</div>
                    <div v-if="detailsData.imgs&&detailsData.imgs.length!=0" class="evaluate_img">
                        <img @click="lookPic(index)" v-for="(item,index) of detailsData.imgs" :key="index" :src="item" alt="">
                    </div>
                </div>
                <div v-if="detailsData.reply_content" class="evaluate_box">
                    <div>商家回复：</div>
                    <div class="evaluate_txt">{{detailsData.reply_content}}</div>
                </div>
            </div>
        </Modal>
        <!-- 图片查看 -->
        <Modal
            v-model="picModal"
            title="查看">
            <div style="width:100%;position: relative;">
                <div class="page_box">
                    <Icon @click="picIndex-=1" v-if="picIndex!=0" size="50" type="ios-arrow-dropleft-circle" />
                    <div v-else></div>
                    <Icon @click="picIndex+=1" v-if="picIndex!=images.length-1" size="50" type="ios-arrow-dropright-circle" />
                    <div v-else></div>
                </div>
                <img style="width:100%" :src="images[picIndex]" alt="">
            </div>
            <div slot="footer"></div>
        </Modal>
    </div>
</template>

<script>
    import {commentList,commentChange,commentReply,storeComment,details,changeComment,commentdel} from '@/api/evaluation'
    export default {
        data(){
            return{
                total: 0,
                storePrams:{
                    comment_type:null,
                    mode:null,
                    keyword:'',
                    start_time:'',
                    end_time:'',
                    limit: 10,
                    page: 1
                },
                detailsData:{},
                selectList: [
                    {value: 1,label: '好评'},
                    {value: 2,label: '中评'},
                    {value: 3,label: '差评'}
                ],
                selectList1: [
                    {value: 1,label: '堂食'},
                    {value: 2,label: '外卖'},
                    {value: 3,label: '自取'},
                ],
                Columns:[
                    {
                        title: '#',
                        slot: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '模式',
                        slot: 'type',
                        align: 'center'
                    },
                    {
                        title: '用户昵称',
                        key: 'name',
                        align: 'center'
                    },
                    {
                        title: '订单号',
                        key: 'number_no',
                        align: 'center'
                    },
                    {
                        title: '评分',
                        key: 'satisfied',
                        width: 80,
                        align: 'center'
                    },
                    {
                        title: '评价内容',
                        slot: 'content',
                        align: 'center'
                    },
                    {
                        title: '评价时间',
                        key: 'created_at',
                        align: 'center'
                    },
                    {
                        title: '商家回复',
                        slot: 'btn',
                        width: 100,
                        align: 'center'
                    },
                    {
                        title: '状态',
                        slot: 'status',
                        width: 80,
                        align: 'center'
                    },
                    {
                        title: '操作',
                        key: 'action',
                        width: 160,
                        fixed: 'right',
                        align: 'center',
                        slot: 'operation'
                    }
                ],
                data:[],
                images:[
                    'https://img1.baidu.com/it/u=1814112640,3805155152&fm=253&fmt=auto&app=120&f=JPEG?w=889&h=500',
                    'https://img0.baidu.com/it/u=501558154,2179992071&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500',
                    'https://img2.baidu.com/it/u=2438645924,3546232272&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500',
                    'https://img2.baidu.com/it/u=4278586623,3371084641&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=761',
                ],
                is_recharge:null,
                replyModal:false,
                detailModal:false,
                picModal:false,
                picIndex:0,
                replybox:{
                    id:null,
                    reply_content:'',
                }
            }
        },
        created(){
            this.getList()
            this.getstoreComment()
        },
        methods:{
            // 获取商品列表
            getList(){
                commentList(this.storePrams).then(res=>{
                    this.total = res.data.total
                    this.data = res.data.data
                })
            },
            // 获取评论开关状态
            getstoreComment(){
                storeComment().then(res=>{
                    this.is_recharge = res.data.comment_status
                })
            },
            // 评价功能开关
            rechargeChange(e){
                commentChange({comment_status:e}).then(res=>{
                    this.$Message.success(res.msg)
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            // 商家回复按钮
            replyBtn(e){
                this.replybox.id = e.id
                this.replybox.reply_content = e.reply_content
                this.replyModal = true
            },
            // 商家确定
            replyok(){
                commentReply(this.replybox).then(res=>{
                    this.$Message.success(res.msg)
                    this.replyModal = false
                    this.getList()
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            // 商家取消
            replycancel(){
                this.replyModal = false
            },
            // 查看详情按钮
            detailBtn(id){
                details({id:id}).then(res=>{
                    this.detailsData = res.data
                    this.detailModal = true
                })
            },
            // 详情确定按钮
            detailok(){},
            // 搜索
            searchBtn(){
                this.storePrams.page = 1
                this.getList()
            },
            // 评论列表状态开关
            statusChange(id){
                changeComment({id:id}).then(res=>{
                    this.$Message.success(res.msg)
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            // 评论删除
            delBtn(id){
                this.$Modal.confirm({
                    title: '提示',
                    content: `您正在使用删除功能`,
                    onOk: () => {
                        commentdel({id:id}).then(res=>{
                            this.$Message.success(res.msg)
                            this.getList()
                        }).catch(err=>{
                            this.$Message.error(err.msg)
                        })
                    },
                    onCancel: () => {}
                }); 
            },
            // 时间选择
            timeCheck(e){
                this.storePrams.start_time = e[0]
                this.storePrams.end_time = e[1]
            },
            lookPic(e){
                this.picIndex = e
                this.picModal = true
            },
            // 分页
            pageChange(index){
                this.storePrams.page = index
                // this.getList()
            },
        }
    }
</script>

<style scoped>
    .top-change {
        width: 100%;
        background: white;
        border-radius: 12px;
        padding: 20px 30px;
        box-sizing: border-box;
    }
    .top-search {
        width: 100%;
        background: white;
        padding: 20px 0;
        border-radius: 10px;
        display: flex;
    }
    .btn{
        background: #FF761D;
        color: #fff;
        border-color: #FF761D;
        margin-left: 20px;
    }
    .table-wrap {
        width: 100%;
        background: white;
        border-radius: 12px;
        padding: 20px 30px;
        box-sizing: border-box;
        margin-top: 30px;
    }
    .ivu-switch-checked{
        border-color: #FF761D;
        background-color: #FF761D;
    }
    .textareaStyle{
        outline: none;
        width: 100%;
        height: 100px;
        max-height: 100px;
        min-height: 100px;
        box-sizing: border-box;
        border: none;
    }
    .content_box{
        padding: 0 20px;
        color: #333;
    }
    .iten_content{
        margin-bottom: 10px;
    }
    .evaluate_box{
        margin-bottom: 10px;
    }
    .evaluate_txt{
        padding-left: 30px;
    }
    .evaluate_img{
        padding-left: 30px;
        display: flex;
        flex-wrap: wrap;
    }
    .evaluate_img img{
        margin-right: 9px;
        margin-bottom: 9px;
        width: 90px;
        height: 90px;
        object-fit: cover;
    }
    .page_box{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -25px;
    }
    
    .flex{
        display: flex;
    }
    .just_sb{
        justify-content: space-between;
    }
</style>