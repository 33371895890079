<template>
  <div class="son-page">
    <div class="top-change">
      <div class="top-search">
        <Input placeholder="请输入单号、编号、会员姓名、手机号、桌号" v-model="storePrams.keyword" style="width: 300px;margin-right: 20px"/>
        <DatePicker @on-change="timeCheck" type="daterange" placement="bottom-end" placeholder="点击选择日期" style="width: 300px;margin-right: 20px"></DatePicker>
        <Select v-model="storePrams.type" style="width:200px">
          <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
        <div class="two-btns">
          <div @click="searchBtn" class="btn-style">查询</div>
        </div>
        <div class="two-btns">
          <Button @click="exportBtn" style="width:80px">导出</Button>
        </div>
      </div>
    </div>
    <div class="table-wrap">
      <Table :columns="worlkColumns" :data="data6">
        <template slot-scope="{ index }" slot="name">
          <div>{{ index + 1 }}</div>
        </template>
        <template slot-scope="{ row }" slot="type">
          <div v-if="row.type==1">堂食<br/>{{ row.desk_number }}</div>
          <div v-if="row.type==2">外卖</div>
          <div v-if="row.type==3">自取</div>
        </template>
        <template slot-scope="{ row }" slot="business_status">
        
          <span style="color:#FF0000" v-if="row.business_status==-1">取消</span>
          <span style="color:#06CA3F" v-if="row.business_status==1">待接单</span>
          <span style="color:#06CA3F" v-if="row.business_status==2">配餐中</span>
          <span style="color:#06CA3F" v-if="row.business_status==3">待配送</span>
          <span style="color:#06CA3F" v-if="row.business_status==4">待结单</span>
          <span style="color:#06CA3F" v-if="row.business_status==5">待取餐</span>
          <span style="color:#FF761D" v-if="row.business_status==6">结单</span>
          -
          <span style="color:#FF0000" v-if="row.pay_status==1">未付款</span>
          <span style="color:#06CA3F" v-if="row.pay_status==2">已付款</span>
        </template>
        <template slot-scope="{ row }" slot="operation">
          <Button type="info" ghost size="small" style="margin-right: 5px" @click="detailBtn(row)">详情</Button>
          <Button type="primary" ghost size="small" style="margin-right: 5px;color:#2D8CF0;border-color:#2D8CF0;background:none" @click="PrintBtn(row.id)">打印</Button>
        </template>
      </Table>
      <br/>
      <Page :total="total"
            :current="storePrams.page"
            style="text-align:right"
            show-elevator
            show-total
            @on-change="pageChange"
            :page-size="storePrams.limit"/>
    </div>
    <!-- <OrderInfo :show.sync="Modal" :order_id="order_id"></OrderInfo> -->
    <OrderInfo  :order_id.sync="order_id"></OrderInfo>
    <printerWin :PrintModal.sync="PrintModal" :PrintData.sync="PrintData" :dayingStatus="1" :orderType="3"></printerWin>
  </div>
</template>

<script>
import OrderInfo from '@/components/order/info.vue'
import printerWin from '@/components/printer/printerWin.vue'
import {orderRecord,} from '@/api/order'
import config from '@/config';
export default {
  name: "canteen",
  components:{
    OrderInfo,
    printerWin
  },
  data(){
    return{
      PrintData:{
        print_id:0,
        id:null
      },
      PrintModal:false,
      order_id:0,
      Modal:false,
      uploads: config.apiUrl + '/storeapi/orderExport',
      data6:[],
      total: 0,
      detailData:{},
      storePrams:{
        type:'0',
        keyword:'',
        start_time:'',
        end_time:'',
        limit: 10,
        page: 1
      },
      cityList: [
        {
          value: '0',
          label: '所有'
        },
        {
          value: '1',
          label: '堂食'
        },
        {
          value: '2',
          label: '外卖'
        },
        {
          value: '3',
          label: '自取'
        }
      ],
      worlkColumns:[
        {
          title: '#',
          slot: 'name',
          width: 60,
          align: 'center'
        },
        {
          title: '单号',
          key: 'number',
          width: 110,
          align: 'center'
        },
        {
          title: '订单编号',
          key: 'number_no',
          width: 160,
          align: 'center'
        },
        {
          title: '类型',
          slot: 'type',
          width: 100,
          align: 'center'
        },
        {
          title: '时间',
          key: 'created_at',
          width: 180,
          align: 'center'
        },
        {
          title: '订单金额',
          key: 'order_sum',
          width: 110,
          align: 'center'
        },
        {
          title: '实付金额',
          key: 'order_pay_money',
          width: 110,
          align: 'center'
        },
        {
          title: '优惠金额',
          key: 'order_coupon_money',
          width: 110,
          align: 'center'
        },
        {
          title: '会员姓名',
          key: 'name',
          width: 160,
          align: 'center'
        },
        {
          title: '用户编号',
          key: 'member_number',
          width: 100,
          align: 'center'
        },
        {
          title: '状态',
          slot: 'business_status',
          align: 'center'
        },
        {
          title: '操作',
          key: 'action',
          width: 160,
          fixed: 'right',
          align: 'center',
          slot: 'operation'
        }
      ],
      data:[],
      model1: ''
    }
  },
  created() {
    this.getList()
  },
  methods:{
    getList(){
      orderRecord(this.storePrams).then(res=>{
        this.total = res.data.total
        this.data6 = res.data.data
      })
    },
    searchBtn(){
      this.storePrams.page = 1
      this.getList()
    },
    PrintBtn(e){
      
      this.dayingStatus = 1
      this.PrintData.id = e
      this.PrintModal = true
      // a.fromOrderToPrint(e)
      // orderPrint({id:e}).then(res=>{
      //   console.log(res);
      // })
    },
    detailBtn(e){
        this.order_id = e.id
      // if (e.type==1) {
        // recordDetails({id:e.id}).then(res=>{
        //   this.detailData = res.data
        //   this.Modal = true
        // })
      // }else{
      //   this.detailData = e
      //   this.Modal = true
      // }
    },
    pageChange(index){
      this.storePrams.page = index
      this.getList()
    },
    closeModal(){
      this.Modal = false
    },
    exportBtn(){
      location.href = `${this.uploads}?data_id=${this.$store.state.userInfo.data_id}&type=${this.storePrams.type}&keyword=${this.storePrams.keyword}&start_time=${this.storePrams.start_time}&end_time=${this.storePrams.end_time}`
    },
    timeCheck(e){
      this.storePrams.start_time = e[0]
      this.storePrams.end_time = e[1]
    }
  }
}
</script>

<style scoped>
.son-page {

}
.top-change {
  width: 100%;
  background: white;
  border-radius: 12px;
  padding: 20px 30px;
  box-sizing: border-box;
}
.table-wrap {
  width: 100%;
  background: white;
  border-radius: 12px;
  padding: 20px 30px;
  box-sizing: border-box;
  margin-top: 30px;
}
.top-search {
  width: 100%;
  background: white;
  padding: 20px 0;
  border-radius: 10px;
  display: flex;
}
.two-btns {
  display: flex;
  align-items: center;
  margin-left: 30px;
}
.btn-style {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
  background: #FF761D;
  width: 80px;
  height: 32px;
}
.right-btn .btn1 {
  width: 60px;
  height: 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ff5c11;
  color: #ff5c11;
  margin-left: 20px;
  border-radius: 4px;
  cursor: pointer;
}
.right-btn .btn2 {
  color: #343434;
  border: 1px solid #555555;
}
.right-btn .btn2:hover,.right-btn .btn1:hover{
  opacity: 0.6;
}

</style>
<style>
.ivu-tabs-nav .ivu-tabs-tab {
  display: inline-block;
  height: 100%;
  padding: 8px 16px;
  margin-right: 16px;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  font-size: 15px;
  font-weight: bold;
  transition: color .3s ease-in-out;
}
.ivu-tabs-nav .ivu-tabs-tab-active {
  color: #FF761D;
}
.ivu-tabs-nav .ivu-tabs-tab:hover {
  color: #FF761D;
}
.ivu-tabs-ink-bar {
  height: 2px;
  box-sizing: border-box;
  background-color: #FF761D;
  position: absolute;
  left: 0;
  bottom: 1px;
  z-index: 1;
  transition: transform .3s ease-in-out;
  transform-origin: 0 0;
}
</style>
