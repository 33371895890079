<template>
  <div class="page-style">
    <div class="left-menu">
      <div v-for="(item,index) in leftMenu" :key="index">
        <div v-if="item.children&&item.children.length!=0">
          <div class="menu-nav" :class="item.show ? 'active1' : ''" @click="changeLabel(item)">
            <Icon :type="item.icon" size="24" style="margin-right: 10px"/>
            {{ item.title }}
            <Icon v-if="item.show" type="ios-arrow-down" />
            <Icon v-else type="ios-arrow-forward" />
          </div>
          <div v-if="item.show">
            <div v-for="(j,k) in item.children" :key="k">
              <div class="menu-nav" :class="chilIndex === j.src ? 'active' : ''" @click="chilLabel(j)">
                <span class="hezi"></span>
                {{ j.title }}
                <Icon v-if="j.children&&j.children.length!=0" type="ios-arrow-down" />
              </div>
            </div>
          </div>
        </div>
        <div v-else class="menu-nav" :class="activeIndex === item.src ? 'active' : ''" @click="changeLabel(item)">
          <Icon :type="item.icon" size="24" style="margin-right: 10px"/>
          {{ item.title }}
          <div v-if="item.src=='/admin/order/hallFood'&&order_untreated.t_untreated!=0" class="floating">{{order_untreated.t_untreated}}</div>
          <div v-if="item.src=='/admin/order/takeoutfood'&&order_untreated.w_untreated!=0" class="floating">{{order_untreated.w_untreated}}</div>
          <div v-if="item.src=='/admin/order/takefromoneself'&&order_untreated.z_untreated!=0" class="floating">{{order_untreated.z_untreated	}}</div>
        </div>
      </div>
    </div>
    <div class="right-content">
      <div class="content-del">
        <div v-if="activeIndex === '/admin/order/hallFood'"><Canteen @func="getMsgFormSon"></Canteen></div>
        <div v-if="activeIndex === '/admin/order/takeoutfood'"><Takeout @func="getMsgFormSon"></Takeout></div>
        <div v-if="activeIndex === '/admin/order/takefromoneself'"><Invite @func="getMsgFormSon"></Invite></div>
        <div v-if="activeIndex === '/admin/order/history'"><History></History></div>
        <div v-if="chilIndex === '/admin/evaluation/statistics'"><Evaluationstatistics></Evaluationstatistics></div>
        <div v-if="chilIndex === '/admin/evaluation/list'"><Evaluationlist></Evaluationlist></div>
        <div v-if="chilIndex === '/admin/evaluation/label'"><Evaluationlabel></Evaluationlabel></div>
      </div>
    </div>
  </div>
</template>
<script>
import {getUntreated} from '@/api/order'
import Canteen from "./components/canteen";
import Takeout from "./components/takeout";
import History from "./components/history";
import Invite from "./components/invite";

import Evaluationlist from "./components/evaluationlist";
import Evaluationlabel from "./components/evaluationlabel";
import Evaluationstatistics from "./components/evaluationstatistics";

export default {
  components: {Takeout,Canteen,History,Invite,Evaluationlist,Evaluationlabel,Evaluationstatistics},
  data() {
    return {
      leftMenu:[
        {
          icon:'ios-cafe-outline',
          label:'堂食',
          path:'/admin/order/hallFood'
        },
        {
          icon:'ios-bicycle',
          label:'外卖',
          path:'/admin/order/takeoutfood'
        },
        {
          icon:'ios-checkbox-outline',
          label:'自取',
          path:'/admin/order/takefromoneself'
        },
        {
          icon:'ios-clock-outline',
          label:'历史',
          path:'/admin/order/history'
        },
        {
          icon:'ios-contact-outline',
          label:'评价管理',
          path:'/admin/user/member',
          show:false,
          children:[
            {
              icon:'ios-bicycle',
              label:'评价统计',
              path:'/admin/evaluation/statistics'
            },
            {
              icon:'ios-bicycle',
              label:'评价列表',
              path:'/admin/evaluation/list'
            },
            {
              icon:'ios-bicycle',
              label:'评价标签',
              path:'/admin/evaluation/label'
            }
          ]
        }
      ],
      untreated:{
        t_untreated:0,
        w_untreated:0,
        z_untreated:0
      },
      activeIndex:0,
      chilIndex:null
    }
  },
  computed: {
    order_untreated(){
      return this.$store.state.order_untreated
    }
  },
  mounted() {
  },
  created() {
    let menu = this.$store.state.menu
    menu.forEach(item => {
      if (item.id==13) {
        item.children.forEach(j=>{
          if (j.src == '/admin/evaluation/member') {
            j.show = false
          }
        })
        if (this.$store.state.activeIndex==0) {
          this.activeIndex = item.children[this.$store.state.activeIndex].src
        }else{
          this.activeIndex = this.$store.state.activeIndex
        }
        this.leftMenu = item.children
      }
    });
      this.$parent.$parent.$parent.getUntreated()
  },
  methods: {
    getMsgFormSon(data){
      this.untreated = data
    },
    changeLabel(item){
      if (item.children&&item.children.length!=0) {
        item.show = !item.show
        this.activeIndex = null
        if (this.chilIndex==null) {
          this.chilIndex = item.children[0].src
        }
      }else{
        this.leftMenu.forEach(item=>{
          if (item.show) {
            item.show = false
          }
        })
        this.$store.commit('setActiveIndex', item.src)
        this.chilIndex = null
        this.activeIndex = item.src
      }
    },
    chilLabel(item){
      this.activeIndex = null
      this.chilIndex = item.src
    }
  }
}
</script>
<style scoped>
.page-style {
  display: flex;
}

.left-menu {
  width: 210px;
  /* max-width: 210px;
  min-width: 210px; */
  height: 93vh;
  background: white;
  margin-right: 20px;
  padding: 20px 20px;
  box-sizing: border-box;
}

.right-content {
  /* flex: 1; */
  width: calc(100% - 230px);
  padding: 20px 20px 0 0;
  box-sizing: border-box;
}
.content-del {
  /*background: #fff;*/
  width: 100%;
  height: 88vh;
  border-radius: 8px;
  overflow: auto;
}
.menu-nav {
  border: 1px solid #FFF;
  position: relative;
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
  padding-left: 20px;
  border-radius: 200px;
  box-sizing: border-box;
  margin-bottom: 16px;
}
.menu-nav:hover{
  background: #FFF7F2;
  color: #686868;
}
.menu-nav.active{
  /* background: #FF761D; */
  border: 1px solid #FF761D;
  color: #FF761D;
  /* color: white; */
}
.floating{
  position: absolute;
  top: -5px;
  right: 10px;
  min-width: 20px;
  height: 20px;
  border-radius: 100%;
  background: red;
  color: #FFF;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.active1{
  color: #FF761D;
}
.hezi{
  display: block;
  width: 24px;
  height: 1px;
  margin-left: 10px;
}

</style>
