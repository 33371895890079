import request from '@/utils/request'

export function basicXq (data) {
    return request({
        url: '/apply/basicXq',
        method: 'get',
        params:data
    })
}

export function basicSet (data) {
    return request({
        url: '/apply/basicSet',
        method: 'post',
        data
    })
}

export function indexDeskType (data) {
    return request({
        url: '/apply/indexDeskType',
        method: 'get',
        params:data
    })
}

export function addDeskType (data) {
    return request({
        url: '/apply/addDeskType',
        method: 'post',
        data
    })
}

export function editDeskType (data) {
    return request({
        url: '/apply/editDeskType',
        method: 'post',
        data
    })
}

export function delDeskType (data) {
    return request({
        url: '/apply/delDeskType',
        method: 'post',
        data
    })
}

export function indexDeskArea (data) {
    return request({
        url: '/apply/indexDeskArea',
        method: 'get',
        params:data
    })
}

export function addDeskArea (data) {
    return request({
        url: '/apply/addDeskArea',
        method: 'post',
        data
    })
}

export function editDeskArea (data) {
    return request({
        url: '/apply/editDeskArea',
        method: 'post',
        data
    })
}

export function delDeskArea (data) {
    return request({
        url: '/apply/delDeskArea',
        method: 'post',
        data
    })
}

export function indexPosition (data) {
    return request({
        url: '/apply/indexPosition',
        method: 'get',
        params:data
    })
}

export function lsDeskType (data) {
    return request({
        url: '/apply/lsDeskType',
        method: 'get',
        params:data
    })
}

export function lsDeskArea (data) {
    return request({
        url: '/apply/lsDeskArea',
        method: 'get',
        params:data
    })
}

export function addPosition (data) {
    return request({
        url: '/apply/addPosition',
        method: 'post',
        data
    })
}

export function editPosition (data) {
    return request({
        url: '/apply/editPosition',
        method: 'post',
        data
    })
}

export function delPosition (data) {
    return request({
        url: '/apply/delPosition',
        method: 'post',
        data
    })
}

export function AddsPosition (data) {
    return request({
        url: '/apply/AddsPosition',
        method: 'post',
        data
    })
}

export function indexPrinter (data) {
    return request({
        url: '/apply/indexPrinter',
        method: 'get',
        params:data
    })
}

export function addPrinter (data) {
    return request({
        url: '/apply/addPrinter',
        method: 'post',
        data
    })
}

export function editPrinter (data) {
    return request({
        url: '/apply/editPrinter',
        method: 'post',
        data
    })
}

export function delPrinter (data) {
    return request({
        url: '/apply/delPrinter',
        method: 'post',
        data
    })
}
export function modifyPrintStatus (data) {
    return request({
        url: '/apply/modifyPrintStatus',
        method: 'post',
        data
    })
}

export function lsPosition (data) {
    return request({
        url: '/apply/lsPosition',
        method: 'get',
        params:data
    })
}

export function infoApply (data) {
    return request({
        url: '/apply/infoApply',
        method: 'get',
        params:data
    })
}

export function setApply (data) {
    return request({
        url: '/apply/setApply',
        method: 'post',
        data
    })
}

export function indexCard (data) {
    return request({
        url: '/apply/indexCard',
        method: 'get',
        params:data
    })
}

export function addCard (data) {
    return request({
        url: '/apply/addCard',
        method: 'post',
        data
    })
}

export function setTableArea (data) {
    return request({
        url: '/apply/setTableArea',
        method: 'post',
        data
    })
}

export function delCard (data) {
    return request({
        url: '/apply/delCard',
        method: 'post',
        data
    })
}

export function editCard (data) {
    return request({
        url: '/apply/editCard',
        method: 'post',
        data
    })
}

export function getCate (data) {
    return request({
        url: '/goods/getCate',
        method: 'post',
        data
    })
}

export function infoWechat (data) {
    return request({
        url: '/apply/infoWechat',
        method: 'get',
        params:data
    })
}

export function setWechat (data) {
    return request({
        url: '/apply/setWechat',
        method: 'post',
        data
    })
}

export function setType (data) {
    return request({
        url: '/apply/setType',
        method: 'post',
        data
    })
}

export function issuePrinter (data) {
    return request({
        url: '/apply/issuePrinter',
        method: 'post',
        data
    })
}

export function infoStorage (data) {
    return request({
        url: '/apply/infoStorage',
        method: 'get',
        params:data
    })
}

export function setStorage (data) {
    return request({
        url: '/apply/setStorage',
        method: 'post',
        data
    })
}

export function sharelist (data) {
    return request({
        url: '/apply/share/list',
        method: 'post',
        data
    })
}

export function indexGroup (data) {
    return request({
        url: '/apply/indexGroup',
        method: 'get',
        data
    })
}

export function addGroup (data) {
    return request({
        url: '/apply/addGroup',
        method: 'POST',
        data
    })
}

export function infoGroup (data) {
    return request({
        url: '/apply/infoGroup',
        method: 'get',
        params:data
    })
}

export function editGroup (data) {
    return request({
        url: '/apply/editGroup',
        method: 'POST',
        data
    })
}

export function delGroup (data) {
    return request({
        url: '/apply/delGroup',
        method: 'POST',
        data
    })
}

export function indexUser (data) {
    return request({
        url: '/apply/indexUser',
        method: 'get',
        params:data
    })
}

export function lsGroup (data) {
    return request({
        url: '/apply/lsGroup',
        method: 'get',
        params:data
    })
}

export function addUser (data) {
    return request({
        url: '/apply/addUser',
        method: 'POST',
        data
    })
}

export function editUser (data) {
    return request({
        url: '/apply/editUser',
        method: 'POST',
        data
    })
}

export function delUser (data) {
    return request({
        url: '/apply/delUser',
        method: 'POST',
        data
    })
}

export function listMenu (data) {
    return request({
        url: '/apply/listMenu',
        method: 'get',
        params:data
    })
}

export function declist (data) {
    return request({
        url: '/apply/dec/list',
        method: 'POST',
        data
    })
}

export function decadd (data) {
    return request({
        url: '/apply/dec/add',
        method: 'POST',
        data
    })
}

export function dechandle (data) {
    return request({
        url: '/apply/dec/handle',
        method: 'POST',
        data
    })
}

export function details (data) {
    return request({
        url: '/apply/dec/details',
        method: 'POST',
        data
    })
}

export function decupdate (data) {
    return request({
        url: '/apply/dec/update',
        method: 'POST',
        data
    })
}

export function decdel (data) {
    return request({
        url: '/apply/dec/del',
        method: 'POST',
        data
    })
}

export function templateLs (data) {
    return request({
        url: '/apply/templateLs',
        method: 'get',
        params:data
    })
}

export function mtinfo (data) {
    return request({
        url: '/apply/mt/info',
        method: 'get',
        params:data
    })
}

export function typeinfo (data) {
    return request({
        url: '/apply/mt/typeinfo',
        method: 'get',
        params:data
    })
}

export function create (data) {
    return request({
        url: '/apply/mt/create',
        method: 'POST',
        data
    })
}

export function subscribe (data) {
    return request({
        url: '/apply/subscribe',
        method: 'POST',
        data
    })
}


export function delWxTemplate (data) {
    return request({
        url: '/apply/delWxsubscribe',
        method: 'post',
        data
    })
}

export function add_code (data) {
    return request({
        url: '/apply/add_code',
        method: 'POST',
        data
    })
}

export function disabled (data) {
    return request({
        url: '/apply/mt/disabled',
        method: 'POST',
        data
    })
}

export function printTest (data) {
    return request({
        url: '/apply/printTest',
        method: 'POST',
        data
    })
}

export function getStorage (data) {
    return request({
        url: '/apply/getStorage',
        method: 'POST',
        data
    })
}

export function appMenu (data) {
    return request({
        url: '/apply/appMenu',
        method: 'get',
        params:data
    })
}

export function getDeliveryConf (data) {
    return request({
        url: '/apply/delivery/getDeliveryConf',
        method: 'POST',
        data
    })
}

export function setDeliveryConf (data) {
    return request({
        url: '/apply/delivery/setDeliveryConf',
        method: 'POST',
        data
    })
}

export function switchType (data) {
    return request({
        url: '/apply/delivery/switchType',
        method: 'POST',
        data
    })
}

export function add_account (data) {
    return request({
        url: '/apply/add_account',
        method: 'POST',
        data
    })
}

export function couponSendList (data) {
    return request({
        url: '/apply/couponSendList',
        method: 'POST',
        data
    })
}

export function writeOffRecord (data) {
    return request({
        url: '/apply/writeOffRecord',
        method: 'POST',
        data
    })
}

export function getCoupon (data) {
    return request({
        url: '/apply/getCoupon',
        method: 'POST',
        data
    })
}

export function getUsers (data) {
    return request({
        url: '/apply/getUsers',
        method: 'POST',
        data
    })
}

export function couponSend (data) {
    return request({
        url: '/apply/couponSend',
        method: 'POST',
        data
    })
}

export function waitList (data) {
    return request({
        url: '/apply/waitList',
        method: 'POST',
        data
    })
}

export function writeOff (data) {
    return request({
        url: '/apply/writeOff',
        method: 'POST',
        data
    })
}

export function setPrintNum (data) {
    return request({
        url: '/apply/setPrintNum',
        method: 'POST',
        data
    })
}

export function getPlugin (data) {
    return request({
        url: '/apply/getPlugin',
        method: 'POST',
        data
    })
}

export function couponRechargeList (data) {
    return request({
        url: '/apply/couponRechargeList',
        method: 'POST',
        data
    })
}

export function addRecharge (data) {
    return request({
        url: '/apply/addRecharge',
        method: 'POST',
        data
    })
}

export function updateRecharge (data) {
    return request({
        url: '/apply/updateRecharge',
        method: 'POST',
        data
    })
}

export function delRecharge (data) {
    return request({
        url: '/apply/delRecharge',
        method: 'POST',
        data
    })
}

export function mtAmountList (data) {
    return request({
        url: '/data/mtAmountList',
        method: 'POST',
        data
    })
}

export function getConf (data) {
    return request({
        url: '/apply/zfb/getConf',
        method: 'POST',
        data
    })
}

export function setConf (data) {
    return request({
        url: '/apply/zfb/setConf',
        method: 'POST',
        data
    })
}

export function bannerList (data) {
    return request({
        url: '/apply/integral/bannerList',
        method: 'POST',
        data
    })
}

export function addBanner (data) {
    return request({
        url: '/apply/integral/addBanner',
        method: 'POST',
        data
    })
}

export function bannerDel (data) {
    return request({
        url: '/apply/integral/bannerDel',
        method: 'POST',
        data
    })
}

export function orderList (data) {
    return request({
        url: '/apply/integral/orderList',
        method: 'POST',
        data
    })
}

export function integralhandle (data) {
    return request({
        url: '/apply/integral/handle',
        method: 'POST',
        data
    })
}

export function goodsList (data) {
    return request({
        url: '/apply/integral/goodsList',
        method: 'POST',
        data
    })
}

export function addGoods (data) {
    return request({
        url: '/apply/integral/addGoods',
        method: 'POST',
        data
    })
}

export function goodsOrder (data) {
    return request({
        url: '/apply/integral/goodsOrder',
        method: 'POST',
        data
    })
}

export function getIntegralDetails (data) {
    return request({
        url: '/apply/integral/getIntegralDetails',
        method: 'POST',
        data
    })
}

export function updateGoods (data) {
    return request({
        url: '/apply/integral/updateGoods',
        method: 'POST',
        data
    })
}

export function goodsDel (data) {
    return request({
        url: '/apply/integral/goodsDel',
        method: 'POST',
        data
    })
}

export function getSmsRecord (data) {
    return request({
        url: '/apply/integral/getSmsRecord',
        method: 'POST',
        data
    })
}

export function setAppToken (data) {
    return request({
        url: '/apply/setAppToken',
        method: 'POST',
        data
    })
}

export function sendAuthorization (data) {
    return request({
        url: '/apply/sendAuthorization',
        method: 'POST',
        data
    })
}

export function getShopData (data) {
    return request({
        url: '/apply/getShopData',
        method: 'POST',
        data
    })
}

export function getShopCategory (data) {
    return request({
        url: '/apply/getShopCategory',
        method: 'POST',
        data
    })
}

export function shopCreate (data) {
    return request({
        url: '/apply/shopCreate',
        method: 'POST',
        data
    })
}

export function getPayQrcode (data) {
    return request({
        url: '/apply/face/getPayQrcode',
        method: 'POST',
        data
    })
}

export function setPayQrcode (data) {
    return request({
        url: '/apply/face/setPayQrcode',
        method: 'POST',
        data
    })
}

export function updateFaceStatus (data) {
    return request({
        url: '/apply/face/updateFaceStatus',
        method: 'POST',
        data
    })
}

export function versionList (data) {
    return request({
        url: '/apply/versionList',
        method: 'POST',
        data
    })
}

export function getApproveDetails (data) {
    return request({
        url: '/apply/getApproveDetails',
        method: 'POST',
        data
    })
}

export function versionCancel (data) {
    return request({
        url: '/apply/versionCancel',
        method: 'POST',
        data
    })
}

export function versionApproveCancel (data) {
    return request({
        url: '/apply/versionApproveCancel',
        method: 'POST',
        data
    })
}

export function versionApprove (data) {
    return request({
        url: '/apply/versionApprove',
        method: 'POST',
        data
    })
}

export function experienceCreate (data) {
    return request({
        url: '/apply/experienceCreate',
        method: 'POST',
        data
    })
}

export function versionOnline (data) {
    return request({
        url: '/apply/versionOnline',
        method: 'POST',
        data
    })
}

export function experienceCancel (data) {
    return request({
        url: '/apply/experienceCancel',
        method: 'POST',
        data
    })
}

export function versionDelete (data) {
    return request({
        url: '/apply/versionDelete',
        method: 'POST',
        data
    })
}

export function getShopRegion (data) {
    return request({
        url: '/apply/getShopRegion',
        method: 'POST',
        data
    })
}

export function setOrdinaryConf (data) {
    return request({
        url: '/apply/setOrdinaryConf',
        method: 'POST',
        data
    })
}

export function setQrcode (data) {
    return request({
        url: '/apply/setQrcode',
        method: 'POST',
        data
    })
}

export function setAppWxPay (data) {
    return request({
        url: '/apply/setAppWxPay',
        method: 'POST',
        data
    })
}

export function getHomeTemplate (data) {
    return request({
        url: '/apply/getHomeTemplate',
        method: 'POST',
        data
    })
}

export function setDeskCode (data) {
    return request({
        url: '/apply/setDeskCode',
        method: 'POST',
        data
    })
}

export function integralsetConf (data) {
    return request({
        url: '/apply/integral/setConf',
        method: 'POST',
        data
    })
}

export function integralgetConf (data) {
    return request({
        url: '/apply/integral/getConf',
        method: 'POST',
        data
    })
}

export function getDiyGoods (data) {
    return request({
        url: '/plugin/storeDiy/getDiyGoods',
        method: 'POST',
        data
    })
}

export function setPrintWiredType (data) {
    return request({
        url: '/apply/setPrintWiredType',
        method: 'POST',
        data
    })
}


export function wxBindAuth (data) {
    return request({
        url: '/apply/bind',
        method: 'POST',
        data
    })
}

export function wxVersionList (data) {
    return request({
        url: '/apply/wxVersionList',
        method: 'POST',
        data
    })
}

export function getSubscribeTemplate (data) {
    return request({
        url: '/apply/getSubscribeTemplate',
        method: 'POST',
        data
    })
}

export function setSubscribeTemplate (data) {
    return request({
        url: '/apply/setSubscribeTemplate',
        method: 'POST',
        data
    })
}

export function changeSubscribeTemplate (data) {
    return request({
        url: '/apply/changeSubscribeTemplate',
        method: 'POST',
        data
    })
}

export function getWxConf (data) {
    return request({
        url: '/apply/getWxConf',
        method: 'POST',
        data
    })
}

export function reloadWxCate (data) {
    return request({
        url: '/apply/reloadWxCate',
        method: 'POST',
        data
    })
}

export function setWxConf (data) {
    return request({
        url: '/apply/setWxConf',
        method: 'POST',
        data
    })
}

export function getAuthStatus (data) {
    return request({
        url: '/apply/getAuthStatus',
        method: 'POST',
        data
    })
}

export function uploadPrivate (data) {
    return request({
        url: '/apply/uploadPrivate',
        method: 'POST',
        data
    })
}

export function getWxApproveStatus (data) {
    return request({
        url: '/apply/getWxApproveStatus',
        method: 'POST',
        data
    })
}

export function uploadWxCode (data) {
    return request({
        url: '/apply/uploadWxCode',
        method: 'POST',
        data
    })
}

export function releax (data) {
    return request({
        url: '/apply/releax',
        method: 'POST',
        data
    })
}

export function uploadApprove (data) {
    return request({
        url: '/apply/uploadApprove',
        method: 'POST',
        data
    })
}

export function approveRecord (data) {
    return request({
        url: '/apply/approveRecord',
        method: 'POST',
        data
    })
}

export function updateDomainLocal (data) {
    return request({
        url: '/apply/updateDomainLocal',
        method: 'POST',
        data
    })
}

export function getBasic (data) {
    return request({
        url: '/data/conf/getBasic',
        method: 'POST',
        data
    })
}

export function basicSetsetting (data) {
    return request({
        url: '/data/conf/basicSet',
        method: 'POST',
        data
    })
}

export function getStorePlugin (data) {
    return request({
        url: '/data/conf/getStorePlugin',
        method: 'POST',
        data
    })
}

export function getStoreDiy1 (data) {
    return request({
        url: '/data/conf/getStoreDiy',
        method: 'POST',
        data
    })
}

export function storeDiy (data) {
    return request({
        url: '/data/conf/storeDiy',
        method: 'POST',
        data
    })
}

export function getPickupList (data) {
    return request({
        url: '/plugin/pickup/getPickupList',
        method: 'POST',
        data
    })
}

export function pickupDel (data) {
    return request({
        url: '/plugin/pickup/pickupDel',
        method: 'POST',
        data
    })
}

export function changeStorePickup (data) {
    return request({
        url: '/plugin/pickup/changeStorePickup',
        method: 'POST',
        data
    })
}

export function addPickup (data) {
    return request({
        url: '/plugin/pickup/addPickup',
        method: 'POST',
        data
    })
}

export function changePick (data) {
    return request({
        url: '/plugin/pickup/changePick',
        method: 'POST',
        data
    })
}

export function getPageList (data) {
    return request({
        url: '/apply/page/getPageList',
        method: 'POST',
        data
    })
}

export function depositList (data) {
    return request({
        url: '/plugin/alcohol/depositList',
        method: 'POST',
        data
    })
}

export function getBrand (data) {
    return request({
        url: '/plugin/alcohol/getBrand',
        method: 'POST',
        data
    })
}

export function depositAdd (data) {
    return request({
        url: '/plugin/alcohol/depositAdd',
        method: 'POST',
        data
    })
}

export function depositApprove (data) {
    return request({
        url: '/plugin/alcohol/depositApprove',
        method: 'POST',
        data
    })
}

export function depositTake (data) {
    return request({
        url: '/plugin/alcohol/depositTake',
        method: 'POST',
        data
    })
}

export function depositDel (data) {
    return request({
        url: '/plugin/alcohol/depositDel',
        method: 'POST',
        data
    })
}

export function takeList (data) {
    return request({
        url: '/plugin/alcohol/takeList',
        method: 'POST',
        data
    })
}

export function takeApprove (data) {
    return request({
        url: '/plugin/alcohol/takeApprove',
        method: 'POST',
        data
    })
}

export function brandList (data) {
    return request({
        url: '/plugin/alcohol/brandList',
        method: 'POST',
        data
    })
}

export function brandAdd (data) {
    return request({
        url: '/plugin/alcohol/brandAdd',
        method: 'POST',
        data
    })
}

export function brandDel (data) {
    return request({
        url: '/plugin/alcohol/brandDel',
        method: 'POST',
        data
    })
}

export function alcoholgetConf (data) {
    return request({
        url: '/plugin/alcohol/getConf',
        method: 'POST',
        data
    })
}

export function alcoholsetConf (data) {
    return request({
        url: '/plugin/alcohol/setConf',
        method: 'POST',
        data
    })
}