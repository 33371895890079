<template>
  <div class="son-page">
    <div class="top-change">
      <Tabs value="1" @on-click="changeTab">
        <TabPane :label="label" name="1"></TabPane>
        <TabPane :label="label1" name="3"></TabPane>
        <TabPane :label="label2" name="4"></TabPane>
        <TabPane label="已结单" name="6"></TabPane>
        <TabPane :label="label3" name="1009"></TabPane>
      </Tabs>
      <div class="top-search">
        <Input placeholder="请输入单号、编号、姓名、手机号" v-model="storePrams.keyword" style="width: 400px"/>
        <div class="two-btns">
          <div @click="searchBtn" class="btn-style">查询</div>
        </div>
      </div>
    </div>
    <div v-if="orderList.length!=0" class="order-list">
      <div class="order-item" v-for="(item,index) of orderList" :key="index">
        <div v-if="item.business_status==6" class="status-pic"><img src="../../../assets/status/2.png"/></div>
        <div v-if="item.business_status==-1" class="status-pic"><img src="../../../assets/status/1.png"/></div>
        <div class="l-went">
          
          <div class="top-wrap">
            <div class="top-num">
              <div class="name1">单号：{{item.number}}</div>
              <div class="name1">编号：{{item.number_no}}</div>
              <div class="name1">下单日期：{{item.created_at}}</div>
              <div class="name1">送达时间：{{item.estimated_time?item.estimated_time:'立即送达'}}</div>
            </div>
            <div class="right1">
              <div v-if="item.order_type==0" class="right1name1">普通订单</div>
              <div v-if="item.order_type==1" style="color:#F6C542" class="right1name1">美团订单</div>
              <div v-if="item.order_type==2" style="color:#0192FF" class="right1name1">饿了么订单</div>
              <div>金额: <span style="font-size: 16px;font-weight: bold;color: #ff5c11">{{item.pay_money}}元</span></div>
            </div>
          </div>
          <div class="shop_flex">
            <div v-for="(j,k) of item.goods_data" :key="k" class="bottom-content">
              {{j.goods_name}} <span v-if="j.prices.specs">({{j.prices.specs}})</span>
              <span style="margin-left:10px" v-for="(a,s) of j.attrs" :key="s">
                  <!-- {{a.name}}：{{a.val}}({{j.prices.specs}}) -->
                   {{a.name}}:{{a.val}}
              </span>
              <span v-for="(o,p) of j.foods" :key="p+'s'" style="margin-left:10px">{{o.name}}</span>
              <span style="font-weight:700;margin-left:10px">x {{j.num}}</span>
            </div>
          </div>
          <div v-if="item.remarks" style="margin-top:10px">备注：{{item.remarks}}</div>
        </div>
        <div class="r-bent">
          <div>
            <div class="phone">{{item.w_address}}</div>
            <div class="phone"><span style="margin-right:10px">{{item.w_name}}</span> {{item.w_phone}}</div>
            <div style="padding:10px 0" v-if="item.order_type==1">
              <div>美团配送状态：
                <span v-if="item.mt.status==-1">商家未接单</span>
                <span v-if="item.mt.status==0">待调度</span>
                <span v-if="item.mt.status==20">已接单</span>
                <span v-if="item.mt.status==30">已取货</span>
                <span v-if="item.mt.status==50">已送达</span>
                <span v-if="item.mt.status==99">已取消</span>
              </div>
            </div>
            <div class="QS_box">
              <div v-if="item.mt.ride_name" style="width:200px">骑手姓名：{{item.mt.ride_name}}</div>
              <div v-if="item.mt.ride_phone" style="width:200px">骑手电话：{{item.mt.ride_phone}}</div>
            </div>
          </div>
          <div class="right-btn">
            <div :style="item.pay_status==1?'color:red':'color:green'">{{item.pay_status==1?'未支付':'支付成功'}}</div>
            <!-- <div style="width:65px" @click="Paymentdetails(item)" class="btn1">支付明细</div> -->
            <div style="width:65px" @click="Paymentdetails(item)" class="btn1">订单明细</div>
            <div v-if="item.cancel_status!=0  && storePrams.business_status == '6' " @click="reasonBtn(item)" style="width:65px; border:1px dashed #999; color:#999" class="btn1">{{item.cancel_status==1?'退款原因':item.cancel_status==2?'同意取消':'拒绝取消'}}</div>
            <div v-if="item.business_status==1&&item.is_define==1 && storePrams.business_status != '1009' " @click="receivingBtn(item.id)" class="btn1">接单</div>
            <div v-if="item.business_status==4&&item.is_define==1 && storePrams.business_status != '1009' " @click="statementBtn(item.id)" class="btn1">结单</div>
            <div v-if="item.business_status==3&&item.is_define==1 && storePrams.business_status != '1009' " @click="distributionBtn(item.id)" class="btn1">配送</div>
            <div v-if="(item.business_status==1||item.business_status==2||item.business_status==3||item.business_status==4)&&item.is_cancel==1 && storePrams.business_status != '1009'" @click="cancelBtn(item.id)" class="btn1 btn2">取消</div>
            <div v-if="storePrams.business_status == '1009' && item.cancel_status!=0 " @click="reasonBtn(item)" class="btn1 btn2">处理申请</div>
            <div @click="PrintBtn(item.id)" class="btn1 btn2">打 印</div>
          </div>
        </div>
      </div>
    </div>
    <div class="tishi" v-else>
      <div><img style="width:400px;height:400px" src="../../../assets/order.png" alt=""></div>
      <div>暂无数据~</div>
    </div>
    <div v-if="orderList.length!=0" class="Page_box">
      <Page :total="total"
              :current="storePrams.page"
              style="text-align:right"
              show-elevator
              show-total
              @on-change="pageChange"
              :page-size="storePrams.limit"/>
    </div>
    
    <refundWin  :reasonModal.sync="reasonModal" :reasonData.sync="reasonData"></refundWin>
    <OrderInfo  :order_id.sync="order_id"></OrderInfo>
    <printerWin :PrintModal.sync="PrintModal" :PrintData.sync="PrintData" :dayingStatus="1"  :orderType="3" ></printerWin>
  </div>
</template>

<script>
import OrderInfo from '@/components/order/info.vue'
import refundWin from '@/components/order/refund.vue'
import printerWin from '@/components/printer/printerWin.vue'
import {orderList,handle} from '@/api/order'
export default {
  name: "canteen",
  components:{
    OrderInfo,
    printerWin,
    refundWin
  },
  data(){
    return{
      PrintData:{
        print_id:0,
        id:null
      },
      PrintModal:false,
      order_id:0,
      total:0,
      storePrams:{
        type:2,
        keyword:'',
        page:1,
        limit:10,
        business_status:'1'
      },
      reasonModal:false,
      reasonData:{
        order_id:null,
        type:null,
        cancel_status:null,
        cancel_reason:'',
        merchant_reason:''
      },
      PaymentData:{},
      orderList:[],
      label: (h) => {
        return h('div', [
          h('span', '待接单'),
          h('Badge', {
              props: {
                  count: this.$store.state.order_untreated.w.receive_num
              }
          })
        ])
      },
      label1: (h) => {
        return h('div', [
          h('span', '待配送'),
          h('Badge', {
              props: {
                  count: this.$store.state.order_untreated.w.delivery_num
              }
          })
        ])
      },
      label2: (h) => {
        return h('div', [
          h('span', '待结单'),
          h('Badge', {
              props: {
                  count: this.$store.state.order_untreated.w.wait_num
              }
          })
        ])
      },
      label3: (h) => {
        return h('div', [
          h('span', '售后'),
          h('Badge', {
              props: {
                  count: this.$store.state.order_untreated.w.approve_num
              }
          })
        ])
      },
    }
  },
  computed: {
    order_untreated(){
      return this.$store.state.order_untreated
    }
  },
  created() {
    this.getList()
  },
  methods:{
    getList(){
      orderList(this.storePrams).then(res=>{
        this.total = res.data.total
        this.orderList = res.data.data
      })
    },
    receivingBtn(id){
      this.$Modal.confirm({
        title: '提示',
        content: `您正在使用接单功能`,
        onOk: () => {
          handle({id:id,type:1}).then(res=>{
            this.$Message.success(res.msg)
            
            this.$parent.$parent.$parent.$parent.getUntreated()
            this.$parent.getMsgFormSon()
            this.getList()
          }).catch(err=>{
            this.$Message.error(err.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    // 退款原因
    reasonBtn(e){
      this.reasonData.cancel_reason = e.cancel_reason
      this.reasonData.cancel_status = e.cancel_status
      this.reasonData.order_id = e.id
      this.reasonModal = true
    },
    PrintBtn(e){
      
      this.dayingStatus = 1
      this.PrintData.id = e
      this.PrintModal = true
      // a.fromOrderToPrint(e)
      // orderPrint({id:e}).then(res=>{
      //   console.log(res);
      // })
    },
    pageChange(index){
      this.storePrams.page = index
      this.getList()
    },
    cancelBtn(id){
      this.$Modal.confirm({
        title: '提示',
        content: `您正在使用取消功能`,
        onOk: () => {
          handle({id:id,type:-1}).then(res=>{
            this.$Message.success(res.msg)
            
            this.$parent.$parent.$parent.$parent.getUntreated()
            this.$parent.getMsgFormSon()
            this.getList()
          }).catch(err=>{
            console.log(err);
            this.$Message.error(err.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    statementBtn(id){
      this.$Modal.confirm({
        title: '提示',
        content: `您正在使用结单功能`,
        onOk: () => {
          handle({id:id,type:4}).then(res=>{
            this.$Message.success(res.msg)
            
            this.$parent.$parent.$parent.$parent.getUntreated()
            this.$parent.getMsgFormSon()
            this.getList()
          }).catch(err=>{
            this.$Message.error(err.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    distributionBtn(id){
      this.$Modal.confirm({
        title: '提示',
        content: `您正在使用配送功能`,
        onOk: () => {
          handle({id:id,type:2}).then(res=>{
            this.$Message.success(res.msg)
            
            this.$parent.$parent.$parent.$parent.getUntreated()
            this.$parent.getMsgFormSon()
            this.getList()
          }).catch(err=>{
            this.$Message.error(err.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    searchBtn(){
      this.storePrams.page = 1
      this.getList()
    },
    changeTab(e){
      this.storePrams.page = 1
      this.storePrams.business_status = e
      this.getList()
    },
    Paymentdetails(e){
      this.order_id = e.id
      this.PaymentData = e
    }
  }
}
</script>

<style scoped>
/* .son-page {

} */
.top-change {
  width: 100%;
  background: white;
  border-radius: 12px;
  padding: 20px 30px;
  box-sizing: border-box;
}
.top-search {
  width: 100%;
  background: white;
  /* padding: 20px 0; */
  border-radius: 10px;
  display: flex;
}
.two-btns {
  display: flex;
  align-items: center;
  margin-left: 40px;
}
.btn-style {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
  background: #FF761D;
  width: 80px;
  height: 32px;
}
.order-item {
  margin-top: 20px;
  background: white;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}
.phone {
  font-size: 14px;
  font-weight: bold;
}
.top-num {
  font-size: 13px;
  color: #585858;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.top-num .name1 {
  margin-right: 40px;
}
.bottom-content {
  width: 100%;
  /* margin-right: 20px; */
  font-size: 15px;
  margin-top: 20px;
  word-break: break-all;
}
.r-bent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}
.right1 {
  display: flex;
  align-items: center;
  text-align: right;
}
.right1name1{
  margin-right: 20px;
  font-size: 20px;
}
.l-went {
  flex: 1;
}
.right-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.right-btn .btn1 {
  width: 60px;
  height: 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ff5c11;
  color: #ff5c11;
  margin-left: 20px;
  border-radius: 4px;
  cursor: pointer;
}
.right-btn .btn2 {
  color: #343434;
  border: 1px solid #555555;
}
.right-btn .btn2:hover,.right-btn .btn1:hover{
  opacity: 0.6;
}
.status-pic {
  width: 117px;
  height: 88px;
  position: absolute;
  right: 210px;
  top: 50%;
  transform: translateY(-50%);
}
.status-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.top-wrap {
  display: flex;
  align-items: center;
  border-bottom: 1px dotted#eee;
  padding-bottom: 10px;
  justify-content: space-between;
}
.tishi{
  text-align: center;
  color: #999;
  /* line-height: 100px; */
}
.textar_style{
  width: 100%;
  min-height: 200px;
  max-height: 200px;
  height: 200px;
  outline: none;
  padding: 10px;
  border-radius: 12px;
  border-color: #999;
}
.dish_da_box{
  max-height: 600px;
  overflow: auto;
}
.flex{
  display: flex;
}
.align-center{
  align-items: center;
}
.just-sb{
  justify-content: space-between;
}
.size_18{
  font-size: 16px;
}
.color_FF8400{
  color: #FF8400;
}
</style>
<style>
.ivu-tabs-nav .ivu-tabs-tab {
  display: inline-block;
  height: 100%;
  padding: 8px 16px;
  margin-right: 16px;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  font-size: 15px;
  font-weight: bold;
  transition: color .3s ease-in-out;
}
.ivu-tabs-nav .ivu-tabs-tab-active {
  color: #FF761D;
}
.ivu-tabs-nav .ivu-tabs-tab:hover {
  color: #FF761D;
}
.ivu-tabs-ink-bar {
  height: 2px;
  box-sizing: border-box;
  background-color: #FF761D;
  position: absolute;
  left: 0;
  bottom: 1px;
  z-index: 1;
  transition: transform .3s ease-in-out;
  transform-origin: 0 0;
}
.shop_flex{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}
.ivu-badge{
  margin-left: 5px;
}
.order-list{
  width: 100%;
  height: 65vh;
  overflow: auto;
}
.QS_box{
  display: flex;
  align-items: center;
}
.Page_box{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  border-radius: 12px;
  width: 100%;
  height: 70px;
  background: #FFF;
  margin-top: 20px;
}
</style>
