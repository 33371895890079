<template>
  <div class="son-page">
    <div class="top-change">
      <Tabs value="1" @on-click="changeTab">
        <TabPane :label="label" name="1"></TabPane>
        <TabPane :label="label1" name="2"></TabPane>
        <TabPane :label="label2" name="5"></TabPane>
        <TabPane label="已结单" name="6"></TabPane>
        <TabPane :label="label3" name="1009"></TabPane>
      </Tabs>
      <div class="top-search">
        <div style="display:flex">
          <Input placeholder="请输入单号、编号、姓名、手机号、取餐码" v-model="storePrams.keyword" style="width: 400px"/>
          <div class="two-btns">
            <Button style="background:#FF761D;color:#FFF" @click="searchBtn">查询</Button>
          </div>
        </div>
        <div v-if="storePrams.business_status==5 && z_take_status" style="display:flex;margin-left:60px">
          <Input placeholder="请输入取餐码" v-model="search.z_number" style="width: 200px"/>
          <div class="two-btns">
            <Button style="background:#FF761D;color:#FFF" @click="WriteBtn">核销</Button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="orderList.length!=0" class="order-list">
      <div class="order-item" v-for="(item,index) of orderList" :key="index">
        <div v-if="item.business_status==6" class="status-pic"><img src="../../../assets/status/2.png"/></div>
        <div v-if="item.business_status==-1" class="status-pic"><img src="../../../assets/status/1.png"/></div>

        <div class="l-went">
          <div class="top-wrap">
            <div class="top-num">
              <div class="name1">单号：{{item.number}}</div>
            <div class="name1">编号：{{item.number_no}}</div>
            <div class="name1">下单日期：{{item.created_at}}</div>
            <div class="name1">取餐时间：{{item.estimated_time?item.estimated_time:'现在取餐'}}</div>
            </div>
            <div class="right1">
              <div v-if="item.z_number" style="margin-right:20px;font-weight:600">取餐码：{{item.z_number}}</div>
              <div style="margin-right:30px;font-weight:600;font-size:16px">{{item.z_eat_type==1?'店内就餐':'打包外带'}}</div>
              <div>金额: <span style="font-size: 16px;font-weight: bold;color: #ff5c11">{{item.pay_money}}元</span> </div>
            </div>
          </div>
          <div class="shop_flex">
            <div v-for="(j,k) of item.goods_data" :key="k" class="bottom-content">
              {{j.goods_name}} <span v-if="j.prices.specs">({{j.prices.specs}})</span>
              <span style="margin-left:10px" v-for="(a,s) of j.attrs" :key="s">
                  <!-- {{a.name}}：{{a.val}}({{j.prices.specs}}) -->
                   {{a.name}}:{{a.val}}
              </span>
              <span v-for="(o,p) of j.foods" :key="p+'s'" style="margin-left:10px">{{o.name}}</span>
              <span style="font-weight:700;margin-left:10px">x {{j.num}}</span>
            </div>
          </div>
          <div v-if="item.remarks" style="margin-top:10px">备注：{{item.remarks}}</div>
        </div>
        <div class="r-bent">
          <div>
            <!-- <div class="phone">{{item.w_address}}</div> -->
            <div class="phone"><span style="margin-right:10px">{{item.get_name}}</span> {{item.get_phone}}</div>
          </div>
          <div class="right-btn">
            <div :style="item.pay_status==1?'color:red':'color:green'">{{item.pay_status==1?'未支付':'支付成功'}}</div>
            <!-- <div style="width:65px" @click="Paymentdetails(item)" class="btn1">支付明细</div> -->
            <div style="width:65px" @click="Paymentdetails(item)" class="btn1">订单明细</div>
            <div v-if="item.z_check_status  && storePrams.business_status != '1009' " style="width:65px" @click="WriteOffBtn(item)" class="btn1">核销</div>
            <div v-if="item.cancel_status!=0   && storePrams.business_status == '6'  " @click="reasonBtn(item)" style="width:65px; border:1px dashed #999; color:#999" class="btn1">{{item.cancel_status==1?'退款原因':item.cancel_status==2?'同意取消':'拒绝取消'}}</div>
            <div v-if="item.business_status==1 && storePrams.business_status != '1009'" @click="receivingBtn(item.id)" class="btn1">接单</div>
            <div v-if="item.business_status==5 && !item.z_check_status  && storePrams.business_status != '1009'" @click="statementBtn(item.id)" class="btn1">结单</div>
            <div v-if=" item.business_status==2 && storePrams.business_status != '1009' " @click="takeamealBtn(item.id)" class="btn1">请取餐</div>
            <div v-if="(item.business_status==1||item.business_status==2||item.business_status==5) && storePrams.business_status != '1009'" @click="cancelBtn(item.id)" class="btn1 btn2">取消</div>
            <div v-if="storePrams.business_status == '1009' && item.cancel_status!=0 " @click="reasonBtn(item)" class="btn1 btn2">处理申请</div>
            <div @click="PrintBtn(item.id)" class="btn1 btn2">打 印</div>
          </div>
        </div>
      </div>
    </div>
    <div class="tishi" v-else>
      <div><img style="width:400px;height:400px" src="../../../assets/order.png" alt=""></div>
      <div>暂无数据~</div>
    </div>
    <div v-if="orderList.length!=0" class="Page_box">
      <Page :total="total"
              :current="storePrams.page"
              style="text-align:right"
              show-elevator
              show-total
              @on-change="pageChange"
              :page-size="storePrams.limit"/>
    </div>

    <!-- 核销弹框 -->
    <Modal
        v-model="WriteOffModal"
        :mask-closable="false"
        title="核销"
        @on-cancel="WriteOffCancel">
        <div style="display:flex;padding:20px 0">
          <Input v-model="WriteOffData.z_number" placeholder="请输入取餐码"></Input>
          <Button @click="WriteOffOk" style="background:#FF761D;color:#FFF;margin-left:20px">核销</Button>
        </div>
        <div slot="footer"></div>
    </Modal>
    <!-- 核销详情弹框 -->
    <Modal
        v-model="WriteModal"
        :mask-closable="false"
        @on-cancel="WriteCancel">
        <p slot="header" style="text-align:center;font-weight:600">
            <span>自取订单</span>
        </p>
        <div style="max-height:700px;overflow:auto;padding:0 20px">
          <Tabs @on-click="checkBtn" :value="nameValue">
              <TabPane v-for="(detailData,index) of WriteOffList" :key="index" :label="`订单${index+1}`" :name="JSON.stringify(index)">
                <div v-if="detailData.desk" class="desk_number"><span style="font-size:20px">{{detailData.desk}}</span> 号桌</div>
          <div style="display:flex;justify-content: space-between;font-size:16px">
            <div>单号：{{detailData.number}}</div>
            <div>订单编号：{{detailData.number_no}}</div>
          </div>
          <div v-if="detailData.name" class="content">用户昵称 : {{detailData.name}}</div>
          <div v-if="detailData.member_number" class="content">用户编号 : {{detailData.member_number}}</div>
          <div v-if="detailData.w_name" class="content">姓名 : {{detailData.w_name}}</div>
          <div v-if="detailData.w_phone" class="content">联系方式 : {{detailData.w_phone}}</div>
          <div v-if="detailData.w_address" class="content">地址 : {{detailData.w_address}}</div>
          <div style="margin-top:10px">
            <div style="display:flex;justify-content: space-between;font-size:16px;margin-bottom:10px">
              <div style="font-weight:600">订单明细（共{{detailData.goods_data.length}}份）</div>
              <div>下单时间：{{detailData.created_at}}</div>
            </div>
            <div style="display:flex;justify-content: space-between;" class="item-shops" v-for="(item,index) of detailData.goods_data" :key="index">
              <div>
                {{item.goods_name}}
                <span v-for="(a,s) of item.attrs" :key="s">{{a.val}} ({{item.prices.specs}})</span>
                <span style="font-weight:700;margin-left:10px">x {{item.num}}</span>
              </div>
              <div style="font-weight:700;margin-left:10px">￥{{item.prices.price_sum}}</div>
            </div>
            <div v-if="detailData.desk_num!=0" style="display:flex;justify-content: space-between;">
              <div>餐位费 <span style="font-weight:700;"> x{{detailData.desk_num}}</span></div>
              <div style="font-weight:700;">￥{{detailData.seat_money}}</div>
            </div>
            <div v-if="detailData.remarks" style="display:flex;margin-top:5px">
              <div style="width：100px">备注信息：</div>
              <div style="flex:1">{{detailData.remarks}}</div>
            </div>
            <div style="margin-top:10px;color:#333">
              <div v-if="Number(detailData.dec_money)!=0" style="display:flex;justify-content: space-between;">
                <div>立减优惠</div>
                <div style="font-weight:700;">￥{{detailData.dec_money}}</div>
              </div>
              <div v-if="Number(detailData.delivery_money)!=0" style="display:flex;justify-content: space-between;">
                <div>配送费</div>
                <div style="font-weight:700;">￥{{detailData.delivery_money}}</div>
              </div>
              <div v-if="Number(detailData.discount_money)!=0" style="display:flex;justify-content: space-between;">
                <div>会员折扣</div>
                <div style="font-weight:700;">-￥{{detailData.discount_money}}</div>
              </div>
              <div style="display:flex;justify-content: space-between;">
                <div style="color:#999">原价：￥{{detailData.money}}</div>
                <div style="font-weight:700;color:#FF761D">优惠价：￥{{detailData.pay_money}}</div>
              </div>
              <div style="display:flex;justify-content: flex-end;">
                <div :style="detailData.pay_status==1?'color:red':'color:#01B581'">{{detailData.pay_status==1?'未付款':'已付款'}}</div>
              </div>
            </div>
          </div>

          <div style="margin-top:10px;line-height:25px">
            <div style="font-weight:600;font-size:16px">整单信息</div>
            <div style="display:flex;justify-content: space-between;margin-top:10px">
              <div>总单价</div>
              <div style="font-weight:700;">￥{{detailData.order_sum}}</div>
            </div>
            <div v-if="Number(detailData.discount_money)!=0" style="display:flex;justify-content: space-between;">
              <div>会员总折扣</div>
              <div style="font-weight:700;">-￥{{detailData.discount_money}}</div>
            </div>
            <div v-if="Number(detailData.coupon_money)!=0" style="display:flex;justify-content: space-between;">
              <div>商家优惠券</div>
              <div style="font-weight:700;">-￥{{detailData.coupon_money}}</div>
            </div>
            <div style="display:flex;justify-content: space-between;margin-top:10px">
              <div>应付金额</div>
              <div style="font-weight:700;">￥{{detailData.pay_money}}</div>
            </div>
            <div v-if="detailData.order_pay_money!=0" style="display:flex;justify-content: space-between;">
              <div>已支付金额</div>
              <div style="font-weight:700;">￥{{detailData.order_pay_money}}</div>
            </div>
            <div v-if="detailData.wx_pay_money" style="display:flex;justify-content: space-between;font-size:12px">
              <div style="margin-left:20px">微信支付</div>
              <div style="font-weight:700;">￥{{detailData.wx_pay_money}}</div>
            </div>
            <div v-if="detailData.zfb_pay_money" style="display:flex;justify-content: space-between;font-size:12px">
              <div style="margin-left:20px">支付宝支付</div>
              <div style="font-weight:700;">￥{{detailData.zfb_pay_money}}</div>
            </div>
            <div v-if="detailData.yue_pay_money" style="display:flex;justify-content: space-between;font-size:12px">
              <div style="margin-left:20px">余额支付</div>
              <div style="font-weight:700;">￥{{detailData.yue_pay_money}}</div>
            </div>
            <div v-if="detailData.cash_pay_money" style="display:flex;justify-content: space-between;font-size:12px">
              <div style="margin-left:20px">现金支付</div>
              <div style="font-weight:700;">￥{{detailData.cash_pay_money}}</div>
            </div>
            <div v-if="Number(detailData.wait_pay_money)!=0" style="display:flex;justify-content: space-between;color:#FF761D">
              <div>未支付金额</div>
              <div style="font-weight:700;">￥{{detailData.wait_pay_money}}</div>
            </div>
            <!-- <div style="text-align: right;">
              <Button @click="WriteOk(detailData)" style="background:#FF761D;color:#FFF;">核销</Button>
            </div> -->
          </div>
              </TabPane>
          </Tabs>
        </div>
        <div slot="footer">
          <Button @click="WriteCancel">取消</Button>
          <Button @click="WriteOk" style="background:#FF761D;color:#FFF;">核销订单{{JSON.parse(nameValue)+1}}</Button>
        </div>
    </Modal>
    <refundWin  :reasonModal.sync="reasonModal" :reasonData.sync="reasonData"></refundWin>
    <OrderInfo  :order_id.sync="order_id"></OrderInfo>
    <printerWin :PrintModal.sync="PrintModal" :PrintData.sync="PrintData" :dayingStatus="1"  :orderType="2" ></printerWin>
  </div>
</template>

<script>
import printerWin from '@/components/printer/printerWin.vue'
import refundWin from '@/components/order/refund.vue'
import OrderInfo from '@/components/order/info.vue'
import {orderList,handle,orderWriteOff,getTakeCodeList} from '@/api/order'
export default {
  name: "canteen",
  components:{
    OrderInfo,
    printerWin,
    refundWin
  },
  data(){
    return{
      PrintData:{
        print_id:0,
        id:null
      },
      PrintModal:false,
      order_id:0,
      total:0,
      search:{
        z_number:null
      },
      z_take_status:null,
      nameValue:'0',
      storePrams:{
        type:3,
        keyword:'',
        page:1,
        limit:10,
        business_status:'1'
      },
      reasonModal:false,
      reasonData:{
        order_id:null,
        type:null,
        cancel_status:null,
        cancel_reason:'',
        merchant_reason:''
      },
      WriteOffData:{
        id:0,
        z_number:null,
      },
      WriteOffList:[],
      PaymentData:{},
      WriteOffModal:false,
      WriteModal:false,
      orderList:[],
      label: (h) => {
        return h('div', [
          h('span', '待接单'),
          h('Badge', {
              props: {
                  count: this.$store.state.order_untreated.z.receive_num
              }
          })
        ])
      },
      label1: (h) => {
        return h('div', [
          h('span', '配餐中'),
          h('Badge', {
              props: {
                  count: this.$store.state.order_untreated.z.catering_num
              }
          })
        ])
      },
      label2: (h) => {
        return h('div', [
          h('span', '待取餐'),
          h('Badge', {
              props: {
                  count: this.$store.state.order_untreated.z.get_num
              }
          })
        ])
      },
      label3: (h) => {
        return h('div', [
          h('span', '售后'),
          h('Badge', {
              props: {
                  count: this.$store.state.order_untreated.z.approve_num
              }
          })
        ])
      },
    }
  },
  computed: {
    order_untreated(){
      return this.$store.state.order_untreated
    }
  },
  created() {
    this.getList()
  },
  methods:{
    getList(){
      orderList(this.storePrams).then(res=>{
        this.total = res.data.total
        this.orderList = res.data.data
        this.z_take_status = res.data.z_take_status
      })
    },
    // 退款原因
    reasonBtn(e){
      this.reasonData.cancel_reason = e.cancel_reason
      this.reasonData.cancel_status = e.cancel_status
      this.reasonData.order_id = e.id
      this.reasonModal = true
    },
    // 核销搜索按钮
    WriteBtn(){
      if (!this.search.z_number) return this.$Message.warning('请输入取餐码！')
      getTakeCodeList({z_number:this.search.z_number}).then(res=>{
        console.log(res.data);
        this.WriteOffList = res.data
        // this.nameValue = JSON.stringify(res.data[0].id)
        this.WriteModal = true
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    // 关闭核销详情弹框
    WriteCancel(){
      this.WriteModal = false
      this.nameValue = '0'
    },
    // 确认核销按钮
    WriteOk(){
      let data = {
        id:this.WriteOffList[JSON.parse(this.nameValue)].id,
        z_number:this.WriteOffList[JSON.parse(this.nameValue)].z_number
      }
      orderWriteOff(data).then(res=>{
        this.$Message.success(res.msg)
        this.WriteCancel()
        this.getList()
          this.$parent.$parent.$parent.$parent.getUntreated()
          this.$parent.getMsgFormSon()
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    checkBtn(e){
      this.nameValue = e
    },
    receivingBtn(id){
      this.$Modal.confirm({
        title: '提示',
        content: `您正在使用接单功能`,
        onOk: () => {
          handle({id:id,type:1}).then(res=>{
            this.$Message.success(res.msg)
            this.$parent.$parent.$parent.$parent.getUntreated()
            this.$parent.getMsgFormSon()
            this.getList()
          }).catch(err=>{
            this.$Message.error(err.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    PrintBtn(e){
      
      this.dayingStatus = 1
      this.PrintData.id = e
      this.PrintModal = true
      // a.fromOrderToPrint(e)
      // orderPrint({id:e}).then(res=>{
      //   console.log(res);
      // })
    },
    cancelBtn(id){
      this.$Modal.confirm({
        title: '提示',
        content: `您正在使用取消功能`,
        onOk: () => {
          handle({id:id,type:-1}).then(res=>{
            this.$Message.success(res.msg)
            this.$parent.$parent.$parent.$parent.getUntreated()
            this.$parent.getMsgFormSon()
            this.getList()
          }).catch(err=>{
            this.$Message.error(err.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    statementBtn(id){
      this.$Modal.confirm({
        title: '提示',
        content: `您正在使用结单功能`,
        onOk: () => {
          handle({id:id,type:4}).then(res=>{
            this.$Message.success(res.msg)
            this.$parent.$parent.$parent.$parent.getUntreated()
            this.$parent.getMsgFormSon()
            this.getList()
          }).catch(err=>{
            this.$Message.error(err.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    takeamealBtn(id){
      this.$Modal.confirm({
        title: '提示',
        content: `您正在使用取餐功能`,
        onOk: () => {
          handle({id:id,type:3}).then(res=>{
            this.$Message.success(res.msg)
            this.$parent.$parent.$parent.$parent.getUntreated()
            this.$parent.getMsgFormSon()
            this.getList()
          }).catch(err=>{
            this.$Message.error(err.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    searchBtn(){
      this.storePrams.page = 1
      this.getList()
    },
    changeTab(e){
      this.storePrams.page = 1
      this.storePrams.business_status = e
      this.getList()
    },
    pageChange(index){
      this.storePrams.page = index
      this.getList()
    },
    Paymentdetails(e){
      this.order_id = e.id
      this.PaymentData = e
    },
    WriteOffBtn(e){
      this.WriteOffData.id = e.id
      this.WriteOffModal = true
    },
    WriteOffCancel(){
      this.WriteOffData.z_number = null
      this.WriteOffModal = false
    },
    WriteOffOk(){
      orderWriteOff(this.WriteOffData).then(res=>{
        this.$Message.success(res.msg)
        this.WriteOffCancel()
        this.getList()
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
  }
}
</script>

<style scoped>
/* .son-page {

} */
.top-change {
  width: 100%;
  background: white;
  border-radius: 12px;
  padding: 20px 30px;
  box-sizing: border-box;
}
.top-search {
  width: 100%;
  background: white;
  /* padding: 20px 0; */
  border-radius: 10px;
  display: flex;
}
.two-btns {
  display: flex;
  align-items: center;
  margin-left: 40px;
}
.btn-style {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
  background: #FF761D;
  width: 80px;
  height: 32px;
}
.order-item {
  margin-top: 20px;
  background: white;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}
.phone {
  font-size: 14px;
  font-weight: bold;
}
.top-num {
  font-size: 13px;
  color: #585858;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.top-num .name1 {
  margin-right: 40px;
}
.bottom-content {
  width: 100%;
  margin-right: 20px;
  font-size: 15px;
  margin-top: 20px;
  word-break: break-all;
}
.r-bent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}
.right1 {
  text-align: right;
  display: flex;
  align-items: center;
}
.l-went {
  flex: 1;
}
.right-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.right-btn .btn1 {
  width: 60px;
  height: 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ff5c11;
  color: #ff5c11;
  margin-left: 20px;
  border-radius: 4px;
  cursor: pointer;
}
.right-btn .btn2 {
  color: #343434;
  border: 1px solid #555555;
}
.right-btn .btn2:hover,.right-btn .btn1:hover{
  opacity: 0.6;
}
.status-pic {
  width: 117px;
  height: 88px;
  position: absolute;
  right: 210px;
  top: 50%;
  transform: translateY(-50%);
}
.status-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.top-wrap {
  display: flex;
  align-items: center;
  border-bottom: 1px dotted#eee;
  padding-bottom: 10px;
  justify-content: space-between;
}
.tishi{
  text-align: center;
  color: #999;
  /* line-height: 100px; */
}
.textar_style{
  width: 100%;
  min-height: 200px;
  max-height: 200px;
  height: 200px;
  outline: none;
  padding: 10px;
  border-radius: 12px;
  border-color: #999;
}
.dish_da_box{
  max-height: 600px;
  overflow: auto;
}
.flex{
  display: flex;
}
.align-center{
  align-items: center;
}
.just-sb{
  justify-content: space-between;
}
.size_18{
  font-size: 16px;
}
.color_FF8400{
  color: #FF8400;
}
</style>
<style>
.ivu-tabs-nav .ivu-tabs-tab {
  display: inline-block;
  height: 100%;
  padding: 8px 16px;
  margin-right: 16px;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  font-size: 15px;
  font-weight: bold;
  transition: color .3s ease-in-out;
}
.ivu-tabs-nav .ivu-tabs-tab-active {
  color: #FF761D;
}
.ivu-tabs-nav .ivu-tabs-tab:hover {
  color: #FF761D;
}
.ivu-tabs-ink-bar {
  height: 2px;
  box-sizing: border-box;
  background-color: #FF761D;
  position: absolute;
  left: 0;
  bottom: 1px;
  z-index: 1;
  transition: transform .3s ease-in-out;
  transform-origin: 0 0;
}
.shop_flex{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}
.ivu-badge{
  margin-left: 5px;
}
.order-list{
  width: 100%;
  height: 65vh;
  overflow: auto;
}
.Page_box{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  border-radius: 12px;
  width: 100%;
  height: 70px;
  background: #FFF;
  margin-top: 20px;
}
</style>
