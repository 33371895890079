<template>
    <div>
        
        <!-- 打印弹框 -->
        <Modal
            v-model="PrintModal"
            :mask-closable="false"
            :closable="false"
            title="选择打印方式">
            <div class="Print_box">
                <div>
                    <div @click="singleChange"><Radio class="radio_box" v-model="single">正常打印</Radio></div>
                    <div style="margin-top:5px" class="flex align-center">
                    <Icon :size="20" style="color:#FFBE1B;margin-right:5px" type="md-information-circle" />
                    按照订单流程正常打印
                    </div>
                </div>
                <div>
                    <div @click="singleChange1"><Radio class="radio_box" v-model="single1">指定打印机</Radio></div>
                    <div style="margin-top:5px" class="flex align-center">
                    <Icon :size="20" style="color:#FFBE1B;margin-right:5px" type="md-information-circle" />
                    仅打印结算单
                    </div>
                </div>
            </div>
            <div slot="footer">
                <Button @click="Printcancel">取消</Button>
                <Button @click="Printok">确认</Button>
            </div>
        </Modal>

        <Modal
            v-model="checkPrintModal"
            :closable="false"
            :mask-closable="false"
            title="选择打印机"
            @on-cancel="checkPrintModalcancel">
            <div class="check_Print_box">
                <div v-for="(item,index) of PrintList" :key="index" style="margin-bottom:5px">
                    <RadioGroup v-model="PrintData.print_id">
                        <Radio class="radio_box" :label="item.id"><span>{{item.print_name}}</span></Radio>
                    </RadioGroup>
                </div>
            </div>
            <div slot="footer">
                <Button @click="checkPrintModalcancel">取消</Button>
                <Button class="btn" @click="checkPrintModalok">确定</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import a from "@/utils/websocket";
import {getPrint,printOrder,plusPrint, orderPrint} from '@/api/order'
export default {
    name: 'printerWin',
    props:{
        orderType:{
            type: Number,
            default: 1
        },
        PrintModal:{
            type:Boolean,
            default:true
        },
        dayingStatus:{
            type:Number,
            default:1
        },
        PrintData:{
            type:Object,
            default:{}
        }
    },
    data(){
        return {
            single:true,
            single1:false,
            checkPrintModal:false,
            PrintList:{
                print_id:0
            }
        }
    },
    methods:{
        
        Printok(){
            let type = 3
            if (this.dayingStatus==1) {
                type = 3;
            }else{
                type = 2;
            }
            if(this.PrintData.print_id == 0 || this.PrintData.print_id == "" || this.PrintData.print_id == null || this.PrintData.print_id == undefined) {
                a.getUsbOrderData({order_id:this.PrintData.id,type:type});
            }
            if (type == 3) {
                this.startPrintOrder();
            } else {
                plusPrint(this.PrintData).then(res=>{
                    this.$Message.success(res.msg)
                    this.clearPrint()
                    this.PrintData.print_id = 0;
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            }
        },
        Printcancel(){
            this.clearPrint()
        },
        
        singleChange(){
            this.$emit('update:PrintData', {
                print_id:0,
                id: this.PrintData.id
            })
            // this.PrintData.print_id = 0
            this.single = true
            this.single1 = false
        },
        singleChange1(){
            getPrint().then(res=>{
                this.PrintList = res.data
                this.checkPrintModal = true
            })
        },
        
        startPrintOrder(){
            if (this.orderType == 1) {
                printOrder(this.PrintData).then(res=>{
                    this.PrintData.print_id = 0;
                    this.$Message.success(res.msg)
                    this.clearPrint()
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            } else {
                orderPrint(this.PrintData).then(res=>{
                    this.PrintData.print_id = 0;
                    this.$Message.success(res.msg)
                    this.clearPrint()
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            }
            
        },

        checkPrintModalok(){
            if (!this.PrintData.print_id) return this.$Message.warning('请选择打印机')
            this.checkPrintModal = false
            this.$emit('update:PrintModal', false)
            
            this.$emit('update:PrintData', {
                print_id:0,
                id: 0
            })
            this.single = false
            this.single1 = false
            this.Printok();
        },
        checkPrintModalcancel(){
            this.checkPrintModal = false
            
            this.$emit('update:PrintData', {
                print_id:null,
                id: this.PrintData.id
            })
            // this.PrintData.print_id = null
            this.single = true
            this.single1 = false
        },
        
        clearPrint(){
            
            this.$emit('update:PrintData', {
                print_id:null,
                id:  null
            })
            this.$emit('update:PrintModal', false)
            
            this.single = true
            this.single1 = false
        }
    }
}
</script>
<style scoped>
    .Print_box{
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
    }
    
    .radio_box{
        border: 1px solid #E6E6E8;
        padding: 10px 30px;
        border-radius: 10px;
    }
    
    .check_Print_box{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
</style>