<template>
    <div>
        <!-- 退款原因弹框 -->
        <Modal :mask-closable="false" v-model="reasonModal" width="360">
            <p slot="header">
                <span>退款原因</span>
            </p>
            <div slot="close" @click="closeCurrent" >
                <i class="ivu-icon ivu-icon-ios-close"></i>
            </div>
            <div>
            {{reasonData.cancel_reason?reasonData.cancel_reason:'用户未填写退款原因~'}}
            </div>
            <div slot="footer">
                <div v-if="reasonData.cancel_status==1">
                <Button @click="reasonSub(99)">拒绝</Button>
                <Button @click="reasonSub(2)" style="background:#FF761D;color:#FFF">同意</Button>
                </div>
                <Button v-if="reasonData.cancel_status==2" >已同意</Button>
                <Button v-if="reasonData.cancel_status==99" >已拒绝</Button>
            </div>
        </Modal>
        <!-- 拒绝退款弹框 -->
        <Modal :mask-closable="false" :closable="false"  v-model="reasonModalName" title="拒绝原因">
            <div>
            <textarea v-model="reasonData.merchant_reason" class="textar_style" placeholder="请输入拒绝原因"></textarea>
            </div>
            <div slot="footer">
                <Button @click="reasoncancel">取消</Button>
                <Button @click="reasonok" style="background:#FF761D;color:#FFF">确定</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import {orderCancelHandle} from '@/api/order'
export default {
    name: "refund",
    props:{
        reasonModal:{
            type:Boolean,
            default:false
        },
        reasonData:{
            type:Object,
            default:{}
        }
    },
    data(){
        return{
            reasonModalName: false,
        }
    },
    mounted(){
        
    },
    methods:{
        closeCurrent(){
            this.$emit('update:reasonModal', false)
        },
        // 退款操作
        reasonSub(e){
            if (e==99) {
                this.reasonModalName = true
            }else{
                let data = {
                    order_id:this.reasonData.order_id,
                    type:e,
                }
                orderCancelHandle(data).then(res=>{
                    this.$parent.$parent.$parent.$parent.$parent.getUntreated()
                    this.$parent.$parent.getMsgFormSon()
                    this.$parent.getList()
                    this.$emit('update:reasonModal', false)
                    this.$Message.success(res.msg)
                }).catch(err=>{
            console.log(err)
                    this.$Message.error(err.msg)
                })
            }
        },
        reasoncancel(){
            this.reasonModalName = false
            let reasonData = this.reasonData
            reasonData.merchant_reason = ''
            this.$emit('update:reasonData', reasonData)
        },
        reasonok(){
            let data = {
                order_id:this.reasonData.order_id,
                type:99,
                merchant_reason:this.reasonData.merchant_reason
            }
            orderCancelHandle(data).then(res=>{
                this.$parent.getList()
                    this.$parent.$parent.$parent.$parent.$parent.getUntreated()
                this.$parent.$parent.getMsgFormSon()
                this.$emit('update:reasonModal', false)
                this.reasoncancel()
                this.$Message.success(res.msg)
            }).catch(err=>{
            console.log(err)
                this.$Message.error(err.msg)
            })
        }
    }
}
</script>
<style>

.textar_style{
  width: 100%;
  min-height: 200px;
  max-height: 200px;
  height: 200px;
  outline: none;
  padding: 10px;
  border-radius: 12px;
  border-color: #999;
}
</style>