import request from '@/utils/request'

export function commentHome (data) {
    return request({
        url: '/order/comment/home',
        method: 'post',
        data
    })
}

export function commentList (data) {
    return request({
        url: '/order/comment/list',
        method: 'post',
        data
    })
}

export function commentChange (data) {
    return request({
        url: '/order/comment/change',
        method: 'post',
        data
    })
}

export function commentReply (data) {
    return request({
        url: '/order/comment/reply',
        method: 'post',
        data
    })
}

export function storeComment (data) {
    return request({
        url: '/order/comment/storeComment',
        method: 'post',
        data
    })
}

export function details (data) {
    return request({
        url: '/order/comment/details',
        method: 'post',
        data
    })
}

export function commentdel (data) {
    return request({
        url: '/order/comment/del',
        method: 'post',
        data
    })
}

export function changeComment (data) {
    return request({
        url: '/order/comment/changeComment',
        method: 'post',
        data
    })
}

export function labellist (data) {
    return request({
        url: '/order/label/list',
        method: 'post',
        data
    })
}

export function labeladd (data) {
    return request({
        url: '/order/label/add',
        method: 'post',
        data
    })
}

export function labeldel (data) {
    return request({
        url: '/order/label/del',
        method: 'post',
        data
    })
}

export function labelchange (data) {
    return request({
        url: '/order/label/change',
        method: 'post',
        data
    })
}

export function changeCommentMode (data) {
    return request({
        url: '/order/comment/changeCommentMode',
        method: 'post',
        data
    })
}